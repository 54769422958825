@import "../../styles/mixins.scss";
@import '../../styles/variables.scss';

.pv-collection-card.pqm-collection-card {
    margin: 0 25px 30px;
}
.pp-view-wrapper {
    height: 100%;
    .pl-explore-btn {
        min-width: 275px;
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
        &.btn-dgreen{
            background-color: #345A4E;
        }
    }
    .carousel-inner {
        max-height: calc(100vh - 575px);
        min-height: calc(100vh - 575px);
        .carousel-item {
            max-height: calc(100vh - 575px); 
            min-height: calc(100vh - 575px);         
            img.card-img-top {                
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .carousel-control-next,  
    .carousel-control-prev {
      width: 36px;
      height: 36px;
      opacity: 1 !important;
      top: calc(50% - 10px); 
    }
    a.carousel-control-next {
        right: 40px;
    }
    a.carousel-control-prev {
        left: 40px;
    }
    .pl-heading,
    .pl-plan-cost { 
        font-size: 3rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
    }
    .pl-subheading {
        margin-bottom: 10px !important;
        margin-top: -10px;
        font-family: 'basic-sans', sans-serif;
        font-size: 2.7rem;
        line-height: 3.3rem;
        letter-spacing: 0.1em;
        font-weight: 400;
        &.pv-heading { font-size: 3.5rem; }
    }
    .pl-plan-cost.address { 
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.01em;
    }
    .pl-plan-cost {
        font-family: 'basic-sans', sans-serif;
        letter-spacing: 0.06em;    
    }
    .activePrice {
        color: $light-green-color;
    }
    .closePrice{
        color: $grey-color2;
    }
    .viewPlan-Availability {
        position: absolute;
        z-index: 3;
        padding: 6px 15px;
        font-size: 1.5rem;
        color: $white-color;
        background: $light-green-color;
    }
    .qmi-explore-image {
        min-height: calc(100vh - 575px);
        background-size: cover;
        background-position: center;
    }
    .viewPlan-AvailMessage {
        position: absolute;
        left: 12px;
        bottom: 15px;
        padding: 9px 20px;
        font-size: 1.5rem;
        background: $purplish-black-color;
        border-radius: 10px;
        color: $white-color;
    }
}
.pv-view-left {
    flex: 0 0 56.5%;
    max-width: 56.5%;
    padding: 50px 30px;
    
}
.pv-view-right {
    flex: 0 0 44%;
    max-width: 44%;
    align-items: center;
    padding: 25px;
    .pv-right-top {
        padding: 0 0 10px;
    }
}
.planview-floormapwrapper {
    height: calc(100vh - 280px);
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-height: 100%;
    }
}
.pv-back-to-landing {
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.2rem;
    letter-spacing: 0.03em;
    color: $white-color;
    .pv-backarrow {
        width: 32px;
        margin-right: 12px;
    }
}
.planCompare {
    width: calc(44% + 100px) !important;
    .offcanvas-body {
        padding: 0;
        .cpl-wrapper {
            overflow-y: auto;
        }
    }
}
.pv-fav-btn {
    border: none;
    background-color: transparent;
}
.vp-plan-btn {
    margin: 0 7px;
}
.pl-explore-btn.vpl-plan-btn {
    min-width: 202px;
    margin: 0 12px;
    &.vpd-button { font-size: 2rem; }
}
.vp-subheading {
    flex: 0 0 68%;
    max-width: 68%;
    margin-right: 10px;
    text-transform: uppercase;
}
.vp-house-desc td {
    padding: 8px;
    font-size: 1.8rem;
    line-height: 3rem;
    letter-spacing: 0.1em;
    border-bottom: 1px solid $grey-color2;
    border-right: 1px solid $grey-color2;
    color: $white-color;
}
button.pv-fav-btn img { width: 37px !important; }
.vp-house-desc td:last-of-type {
    border-right: none;
}
.vp-house-desc tr:last-of-type td {
    border-bottom: none;
}
.vp-check {
     margin-right: 30px; 
     &:last-child {
        margin-right: 0;
    }
     .vp-check-input {
        width: 24px;
        height: 24px;
        margin: 0 10px;
        min-height: auto;
        padding: 0;
        border-color: $dark-green-color;
        background-image: none;
    }
    .vp-check-input:checked[type=radio] {
        background-image: url('../../assets/images/radio-btn.svg');
        background-color: $white-color;
        border-color: $dark-green-color;
        background-size: 20px;
    }    
}
.drs-iframe-SeeIntractivePlan {
    width: 100%;
    height: calc(100% - 15px);
    #theForm {
        width: 100%;
        height: calc(100% - 15px);
    }
    #entireWrapper {
        width: 100%;
    }
}
.comparePlanViewWrapper {
    padding: 0;
    .offcanvas-header {
        display: none;
    }
    .offcanvas-body,.offcanvas-end {
        padding: 0;
    }   
    .dk-MainContentWrapper {
       overflow: hidden;
    }
    .RightMenu {
        padding-right: 0 !important;
    }
}
.planViewWrapper {
    padding: 0;
    .offcanvas-header {
        display: none;
    }
    .offcanvas-body,.offcanvas-end {
        padding: 0;
    }   
    .dk-MainContentWrapper {
       overflow: hidden;
    }
    .dk-right-nav {
        top: 0px !important
    }
}