/* custom scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    background: $light-green-color;
    border-radius: 25px;
}
::-webkit-scrollbar-track {
    background: $grey-color;
    border-radius: 25px;
}
//Common reusuable styles
label {
    padding-bottom: 5px;
    font-size: 2rem;
    line-height: 2.3rem;
    letter-spacing: -0.02em;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
}
input,
input.form-control {
    width: 100%;
    min-height: 62px;
    padding: 0 20px;
    border: none;
    border-radius: 10px;
    font-size: 2rem;
    line-height: 23px;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: $grey-color1;
}
.primary-button {
    min-width: 148px;
    min-height: 48px;
    border-radius: 6px;
    font-size: 2.5rem;
    line-height: 28px;
    font-family: "proxima-nova", sans-serif;
    font-weight: 500;
    letter-spacing: 0.1em;
    background-color: $light-green-color;
    border: none;
    color: $white-color;
    &:hover,
    &:focus {
        background-color: $dark-green-color;
        box-shadow: 3px 2px 15px 3px rgb(255 255 255 / 40%);
        cursor: pointer;
        color: $white-color;
    }
}
.outlined-btn {
    border: 2px solid #FFFFFF;
    background-color: transparent;
    border-radius: 10px;
}
.background-properties {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

// layout structure
.dk-MainContentWrapper {
    overflow-y: auto;
    height: calc(100vh - 107px);  
    width: 100%;

    z-index: 2;
    &.RightMenu,.RightMenu {
        padding-right: 190px;
    }
    &.blackoverlay::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url('../assets/images/transparent-wave-bg.png');
        background-size: cover;
        opacity: 0.35;
        z-index: 1;
    }
}
.blackoverlayTopWave{
    .wevewrapper {
        position: relative;
        z-index: 4;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url('../assets/images/transparent-TopWave-bg.png');
        background-size: cover;
        opacity: 0.35;
        z-index: 1;
    }
}
.slide-exit {
    margin-top: 107px !important;
    .dk-right-nav {
        top: 0px;      
    }
}


// Main landing page curves styles
.curves {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(242, 242, 242, 0.92);
    animation: all-out 0.8s 0.2s ease-out forwards;
    &.curves--in {
        opacity: 0;
        animation: all-in 1s ease-out forwards;
    }
    .top {
        opacity: 0;
        transform: translateY(-100%);
        animation: in-top 0.8s 0.2s ease-out forwards, all-in 1s ease-out forwards;
    }
    .bottom {
        opacity: 0;
        transform: translateY(100%);
        animation: in-bottom 0.8s 0.2s ease-out forwards, all-in 1s ease-out forwards;
    }
}
// Animation for main landing page curves
@keyframes all-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes in-top {
    from {
        tranform: translateY(-100%);
    }
    to {
        transform: translate(0, 0);
    }
}
@keyframes in-bottom {
    from {
        tranform: translateY(100%);
    }
    to {
        transform: translate(0, 0);
    }
}
// Main landing page curves style ends here

//Animation styles

@keyframes box1 {
    from {
        top: 0;
    }
    to {
        top: 80%;
        background: rgba(0, 0, 0, 0.5);
    }
}

@keyframes box2 {
    from {
        top: 0;
    }
    to {
        top: 80%;
        background: rgba(0, 0, 0, 0.5);
    }
}
@keyframes box3 {
    from {
        top: 0;
    }
    to {
        top: 80%;
        background: rgba(0, 0, 0, 0.5);
    }
}
@keyframes box4 {
    from {
        top: 0;
    }
    to {
        top: 80%;
        background: rgba(0, 0, 0, 0.5);
    }
}
.cards {
    position: relative;
    width: 464px;
    max-width: 464px;
    height: 365px;
    font-size: 30px;
    line-height: 38px;
}
.card {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    background-color: #fff;
    border-radius: 7px;
    padding: 0;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
    animation-iteration-count: initial;
    animation-duration: 0.6s;
    border: 20px solid #fff;
    background-size: cover;
    background-position: center;
}
.one {
    transform: translate(110px, -35px) rotate(6deg);
    //  background-image: url('../images/testimonial-image3.png');
}
.two {
    transform: translate(49px, -28px) rotate(1deg);
    //background-image: url('../images/testimonial-image2.png');
}
.three {
    transform: rotate(-5deg);
    //background-image: url('../images/testimonial-image.png');
}
.one {
    animation-name: anim-one;
}
@keyframes anim-one {
    from {
        transform: translate(53px, -35px) rotate(0deg);
    }

    to {
        transform: translate(110px, -35px) rotate(6deg);
    }
}
/* .two {
      animation-name: anim-two;
    }
    @keyframes anim-two {
      from { transform: translate(0, -28px) rotate(-5deg)}
      to { transform: translate(49px, -28px) rotate(1deg);}
  } */
.three {
    animation-name: anim-three;
}
@keyframes anim-three {
    from {
        transform: translate(27px, -28px) rotate(1deg);
    }
    to {
        transform: rotate(-5deg);
    }
}

// tab default
.drs-tabstyle {
   // border: solid 1px red;
    padding: 0;
    flex-grow: unset;
    .nav-tabs {
        margin:0 !important;
        border: none !important;
        .nav-link {
            font-size: 25px;
            line-height: 29px;
            color: $purplish-black-color;
            background: $grey-color2;
            height: 50px;
            border: 0;
            min-width: 200px;
            border-radius: 10px 10px 0 0;
            margin: 0 20px 0 0;
            &.active{
                color:$white-color;
                background: $purplish-black-color;
            }
        }
    }
    .tab-content {
        background: $purplish-black-color;
    }
    .ccw-video-wrapper {
        height: calc(100vh - 220px);
    }
}
.video-tab-close {
    height: 35px;
    button.btn-close {
        margin:60px -10px 25px 0;
        z-index: 99;
    }
}

// Modal Popup 
.Modal-popover {
    //border: solid 5px blue;;
    .modal-dialog {
       // border: solid 1px red;
        min-width: 740px;
        min-height: 430px;
        margin: 0 auto;
        background-image: url('../assets/images/popup-background.png');
        background-size: cover;
        .modal-header {
            border: none;
            background: transparent;
        }
        .modal-content {
           background: transparent;
           border: none;
           button.btn-close {
            margin: 15px 10px 25px 0;
           }
        }
    }
}
.offcanvas-end {
    border: none;
}
/* Promotion Page */
.dk-promotionpage-wrapper{
    height: calc(100vh - 115px) !important;
    iframe {
        width: 100%;
        height: 100%;
        padding: 20px 20px 10px 20px;
    }
}


/* Icomoon fonts icons */
.icon-green-heart:before {
    content: "\e906";
    color: #a1b11a;
}
.icon-Subtract:before {
    content: "\e900";
    color: #fff;
}
.icon-favorite:before {
    content: "\e901";
    color: #fff;
}
.icon-hamburger:before {
    content: "\e902";
    color: #fff;
}
.icon-sign-up:before {
    content: "\e903";
    color: #fff;
}
.icon-why-drees:before {
    content: "\e900";
    color: #fff;
}
.icon-design-inspiration:before {
    content: "\e906";
    color: #fff;
}
.icon-plans:before {
    content: "\e907";
    color: #fff;
}
.icon-home-site:before {
    content: "\e908";
    color: #fff;
}
.icon-community:before {
    content: "\e909";
    color: #fff;
}
.icon-Home:before {
    content: "\e90a";
    color: #fff;
}