@import "../../styles/mixins.scss";
@import '../../styles/variables';
.cc-main-container {
    background-position: center;
    background-size: cover;
    .cc-wrapper {
        max-width: 70%;
        padding: 130px 75px 30px;
        position: relative;
        z-index: 4;
        @include breakpoint(max1400) {
            max-width: 75%; 
            padding: 90px 75px 10px;
        }
        &.os-story-wrapper {
            max-width: 80%;
            .cc-why-drees-title {
                max-width: none;
            }
        }
        &.os-story-btn-wrapper {
            max-width: 80%;
            padding: 30px 75px 30px;
        }
    }
    .cc-why-drees-text {
        padding-bottom: 25px;
        font-family: 'basic-sans', sans-serif;
        font-weight: 400;
        font-size: 3rem;
        font-weight: 400;
        line-height: 3.3rem; 
        letter-spacing: 0.14em;
        color: $light-green-color ;
    }
    .cc-why-drees-title {
        font-family: 'basic-sans', sans-serif;
        font-weight: 400;
        font-size: 6rem;
        line-height: 6.4rem;
        letter-spacing: 0.1em;
        color: $white-color;
    }
    .cc-why-drees-desc {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 400;
        font-size: 4rem;
        line-height: 135.5%;
        letter-spacing: 0.1em;
        color: $white-color;
        @include breakpoint(max1400) { padding-bottom: 25px;}
    }
    .cc-button {
        min-width: 500px;
        min-height: 80px;
        padding: 0 35px;
        margin-bottom: 25px;
        font-size: 3rem;
        line-height: 3.4rem;
        @include breakpoint(max1400) {  min-width: 380px; }
    }
    .cc-slider-wrapper {
        position: absolute;
        right: 265px;
        bottom: 50px;
        width: 400px;
        z-index: 99;    
        text-align: center; 
        img {
            width: 40px;
        }
        @include breakpoint(max1400) { 
            right: 60px;
            bottom: 30px;
        }
    }
    .cc-slider-text {
        padding: 45px 0;
        font-size: 3rem;
        line-height: 3.5rem;
        letter-spacing: 0.1em;
        color: $white-color ;
    }
    .cc-drees-smart-btn { min-width: 690px; }
}
.dk-design-inspi-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:calc(100% - 105px);
    padding: 100px 135px 50px;    
    min-height: 100%;
    height: auto;
    .dk-why-drees-section {
        padding: 0;
        width: auto;
        margin: 0;   
        background: none;     
        overflow: hidden;
    }
    h1 {
        padding-bottom: 25px;
        font-family: 'basic-sans', sans-serif;
        font-weight: 400;
        font-size: 6rem;
        line-height: 6.7rem;
        letter-spacing: 0.1em;
        color: $black-color1;
    }
    p {
        font-size: 3rem;
        line-height: 3.7rem;
        letter-spacing: 0.1em;
        color: $dark-grey-color;
    }
    button.cc-design-inno-btn { min-width: 506px;}
}
.dk-wd-list-animation.dk-design-animation-wrapper {
    justify-content: flex-start;
    margin: 100px 0 70px;
    @include breakpoint(max1400) { margin: 70px 0 60px; }
    .animate-box {
        width: 696px;
        height: 400px;
        margin-right: 42px;
        @include breakpoint(max1400) {
            width: 546px;
            height: 300px;
            margin-right: 42px;
        }
        &.singleInspirationBox {
            margin: 0 auto;
           // width: 750px;
           width: 100%;
           height: 450px;
           .des-inno-animationimage {
            height: auto;
           }
        }
    }
    .des-inno-animationimage {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.ccw-imspirationVideoPanel {
    height: calc(100vh - 310px);
    margin: 0 auto;
    .ccw-imspirationVideo {
        width: 100%;
        height: 100%;
    }
}
.App.DesignInspirationPage {
    .slide-enter,
    .slide-exit,
    .slide-exit-active {
        transform: none !important;
        transition: none !important;
    }
    .slide-exit {
        left: 0px;
    }
}