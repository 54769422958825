@import "../../styles/variables.scss";
@import "../../styles/mixins";

.sm-form-container {
    height: calc(100vh - 95px);
    padding: 0 40px;
    label {
        display: block;
        color: $white-color;
    }
   
}
.sm-field-mainwrapper {
    width: 100%;
    max-width: 1024px;
    height:100%;
    @media only screen and (min-width: 1100px) {
        max-width: 50%;
    }
}  

.login-selected {
    font-size: 2rem;
    color: yellow;
    padding-bottom: 20px;
}
.sm-signup-heading {
    padding-bottom: 0;
    padding-top: 20PX;
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: -0.02em;
    margin-top: -10px;
}

.sm-field-wrapper { 
    flex: 0 0 20%; 
    padding: 0 0 25px 0;
}
.sm-signup-form label {
    display: block;
    color: $white-color;
}
.ccw-watch-video-wrapper.signupWrapper.scan {
    overflow-y: auto;
    padding: 15px 25px 35px 25px !important;
 }

@include breakpoint(max1400) {
    .ccw-watch-video-wrapper.signupWrapper.scan {        
        .sm-field-mainwrapper {           
            margin-bottom: 20px;
        }
        .sm-field-wrapper {
            padding: 0 0 25px 0;
            button.btn.primary-button {
                margin-bottom: 30px;
            }
        }
    }

    .modal-Signup.barcode-popup {
        .modal-dialog {
            min-width: 80% !important;
        }
        .modal-body p {
            padding: 0 25px;
        }
    }
}

