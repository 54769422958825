.offcanvas-backdrop.show {
    opacity: 0 !important;
}
.ccw-watch-video-wrapper {
    padding: 0 25px 25px 25px;
    height: calc(100% - 105px);
    top: 105px !important;
}

.ccw-homesites-wrapper {
    padding: 0 25px 25px 25px;
}

.ccw-close-wrapper {
    padding: 0!important;
    justify-content: flex-end !important;
    button.btn-close{
        padding: 0;
        margin: 10px -12px 25px 0;
        background-image: url('../assets/images/close-green-rounded.svg');
        background-size: contain;
        width: 40px;
        height: 40px;
        opacity: 1;
        border: solid 1px transparent;
        
    }
    &.vg-gallery-close {
        position: absolute;
        right: 25px;
        button.btn-close { 
            background-image: url('../assets/images/close-white-rounded.svg');
        }
    }
}
.ccw-video-wrapper {
    width: 100%;
    height: calc(100% - 50px);
    padding: 25px;
    background: #2B2B2B;
    border: 1px solid #2B2B2B;
}
.ccw-content-wrapper {
    width: 100%;
    height: 100%;
    padding: 25px !important;
}
.ccw-heading {
    padding-top: 10px;
    padding-bottom: 25px;
    font-size: 3rem;
    line-height: 4rem;
    font-family: 'proxima-nova', sans-serif;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: 400;
    color: #fff;
}
.ccw-video-main-wrapper {
    width: 75%;
    height: calc(100% - 110px);
    margin: 5px auto 0 auto;
    padding: 0;
    background-color: #fff;
   
}
.ccw-video {
    height: 100%;
    width: 100%;
}