@import "./planQuickMoveIn.scss";
@import "../../styles/mixins.scss";
@import '../../styles/variables';

.compare-wrapper {
    padding: 0;
    height: calc(100% - 105px);
    top: 105px !important;
    .offcanvas-body {
        padding: 0;
    }
}
.cpl-main-container {
    height: 100%;
    .cpl-wrapper {
        flex: 0 0 55%;
        max-width: 100%;
        height: calc(100vh - 105px);
        padding: 75px 15px;
        background-color: $grey-color;
        overflow: hidden !important;
        .planListWrapper {
            height: calc(100vh - 212px);
            overflow-y: auto;
        }        
        .cpl-close-btn-wrapper {
            background-color: transparent;
            border: none;
        }
        .cpl-close-btn {
            right: 10px;
            top: -60px;
            width: 37px;
        }
        .cpl-heading {
            padding-bottom: 15px;
            font-size: 3rem;
            line-height: 3.6rem;
            letter-spacing: 0.14em;
        }
        .cpl-subheading {
            padding-bottom: 35px;
            font-size: 2rem;
            line-height: 2.7rem;
            letter-spacing: 0.1em;
        }
        .vpl-plan-btn {
            min-width: 410px;
            &.vpd-button { font-size: 2rem; }
        }
        .bordered-btn {
            width: 430px;
            margin: 0 auto;
            border-radius: 10px;
            border: 10px solid rgba(255, 255, 255, 0.5);
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
            button.btn-compareplan {
                background-color: #345A4E;
            }
        }
        .cpl-check .cpl-check-input {
            width: 24px;
            height: 24px;
            min-height: 24px;
            padding: 0;
            margin: 0;
            margin-right: 5px;
            border-radius: 0;
            &:hover { cursor: pointer; }
        }
        .cpl-card {
            position: relative;
            max-width: 280px;
            margin: 0 15px 35px 0;
            border-radius: 10px;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, .05);
        }

        .label-compare,
        .card-text {
            font-size: 1.5rem;
            line-height: 1.7rem;
        }
        .label-compare {
            padding: 0;
            display: flex;
            align-items: center;
            color: $black-color;
            input.cpl-check-input {
                margin-right: 10px;
            }
            &:hover { cursor: pointer; }
        }
        .cpl-plan-name {
            font-size: 2rem;
            line-height: 2.4rem;
            color: $dark-green-color;
        }
        .cpl-card-title {
            margin-bottom: 5px;
        }

        .card-text {
            margin-bottom: 15px;
        }  
        .form-check-input:checked {
            border-color: $dark-green-color;
            background-color: $dark-green-color;
        }
        .col-container {
            display: flex;
            width: 100%;
        }
        .col {
            flex: 1;
            padding: 16px;
        }
    }
}