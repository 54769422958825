@import "../../styles/variables.scss";
.testimonial-video-container {
    height: calc(100vh - 230px);
}
.testi-video-wrapper {
    height: calc(100vh - 230px);
}
.testimonial-offcanvasbody {
    overflow: hidden;
}
.td-testimonial-title {
    padding-bottom: 20px;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: 0.1em;
    color: $purplish-black-color;
}
.td-testimonial-heading {
    min-height: 35px;
    padding-bottom: 10px;
    width: 100%;
    font-size: 2rem;
    line-height: 2.3rem;
    letter-spacing: 0.1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $purplish-black-color;
}
.td-testimonial-vc {
    width: 255px;
}
.td-video-wrapper {
    flex: 0 0 68%;
    padding-right: 15px;
}
.testimonials-list-wrapper {
    flex: 0 0 32%;
    padding-left: 5px;
    padding-right: 15px;
    margin-top: 55px;
    overflow-y: auto;
    // height: 100%;
    max-height: 100%;    
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 20px;
    li {
        max-width: 270px;
        width: 50%;
        margin-bottom: 5px;
    }
}
.td-testi-btn {
    right: 6px;
    top: 6px;
    opacity: 0;
    position: absolute;
    bottom: 6px;
    left: 6px;
    padding: 0 10px;
    min-height: 30px;
    font-size: 1.8rem;
    line-height: 2rem;
}
.testimonials-list {
    margin-bottom: 25px;
    .img-panel {
        width: 100%;
        max-width: 270px;
        height: 205px;
        border-radius: 10px;
        overflow: hidden;
        // width: 255px;
        .td-testimonial-vc {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }
}
.testimonials-list:nth-child(odd) {
    // margin-right: 30px;
}
.ccw-testimonial-videoIframe {
    width: 100%;
    height: calc(100vh - 295px);
}