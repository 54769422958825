.cc-main-container-dreesmart {
    // background-image: url('../../assets/images/dreesmart-background.png');
    background-size: cover;   
    .cc-wrapper {
        max-width: 67%;
        position: relative;
        z-index: 4;
    }
    .cc-why-drees-desc {
        margin: 60px 0 50px 0;
    }
    .cc-why-drees-title {
        max-width: 700px;
    }
}

.ccw-watch-video-wrapper.graph-popover {
    // top: 55px !important;
    // height: calc(100% - 105px);
    // width: 90% !important;
    // left: 5%;
    // padding:0;
    // background: transparent;
    padding: 0;
    .ccw-close-wrapper{
        height: 0;
        button.btn-close {
            z-index: 9;
            margin:80px 20px 0px 0;
            background-image: url('../../assets/images/close-white-rounded.svg');   
            border: solid 1px transparent;     
        }
    }
    .offcanvas-body {
        padding: 0 !important;
        overflow: auto;
    }
    .carousel {
        height: 100%;
        margin: 0;
    }
    .carousel-control-prev {
        opacity: 0 !important;
        display:none;
    }
    .carousel-control-next {
        background-size: contain;
        width: 45px;
        height: 45px;
        right: 125px;
        bottom: 230px;
        top: auto;
        opacity: 1 !important;
    }
}

.drs-energybg {
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    height: calc(100vh - 110px);
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
        max-width: 90%;
    }
    .drs-energyImageTitle {
        position:absolute;
        font-size: 20px;
        color: #ffffff;
        right: 58px;
        bottom: 195px;
        width: 180px;
        text-align: center;
    }
}

.App.DreesSmartInnovationLanding {
    .slide-enter,
    .slide-exit,
    .slide-exit-active {
        transform: none !important;
        transition: none !important;
    }
    .slide-exit {
        left: 0px;
    }
}