@import "../../styles/mixins.scss";
@import '../../styles/variables';
@import "../../styles/communities.scss";

.dk-plan-landing-wrapper {    
    justify-content: flex-end;
    .dk-comm-main-container {
        width: 100%;
        height: auto;       
        padding: 0 35px;       
        min-height: calc(100vh - 470px);
        max-height: calc(100vh - 470px);
        justify-content: center;
        overflow-y: auto;
        .maxCardNumber {
            width: 100%;
            max-width: 1650px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;               
        }
        .card {
            position: relative;
            flex-direction: row;
            padding: 15px;
            max-width: 500px;
            height: 456px;
            margin-bottom: 40px;
            margin-right: 40px;
            border: solid 1px transparent;           
            .card-body {
                flex: auto;
                max-width: 100%;
                overflow: hidden;
            }
            .dk-plans-btn {
                width: 100%;
                margin-top: 15px;
                letter-spacing: 0.1em;
                font-size: 2rem;
                line-height: 2.3rem;
                border-radius: 5px;
            }
            .card-img-top {
                width: 480px;
                height:280px;
                object-fit: cover;
                @include breakpoint(max1400)  {  
                    width: 382px;
                }
            }
            &:nth-child(3n + 3) {
                margin-right: 0;
                line-break: loose;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .dk-plans-header {
        padding-bottom: 70px;
        .dk-plan-title {
            flex: 0 0 100%;
            line-height: 4.8rem;
            letter-spacing: 0.1em;
        }
        .dk-plan-pricerange {
            flex: 0 0 50%;
            letter-spacing: 0.14em;
            line-height: 3.4rem;
        }
    }
}
.App.PlanLandingPage {
    .slide-enter,
    .slide-exit,
    .slide-exit-active {
        transform: none !important;
        transition: none !important;
    }
    .slide-exit {
        left: 0px;
    }
}