@import "../../styles/mixins.scss";
@import '../../styles/variables';

.dk-right-nav {
    position: absolute;
    right: 0;
    top: 105px;
    bottom: 0;
    z-index: 4;
    min-width: 188px;
    background-color: rgba(15,15,15,.8);       
    .dk-rnav-wrapper {
        display: block;
        padding-bottom: 10px;
        &::before {
            font-size: 4.8rem;
            @include breakpoint(max1800) {
                font-size: 4rem;
            } 
        }
    }
    .dk-nav-link {
        font-size: 2rem;
        line-height: 2.3rem; 
        color: $white-color;
        @include breakpoint(max1800) {
            font-size: 1.5rem;
            line-height: 2rem; 
        } 
    }
    .r-nav-listwrapper {
        margin:0;
        padding:0;
        .r-nav-list { 
            padding-bottom: 48px; 
            &:last-child { 
                padding-bottom: 0; 
            }
            @include breakpoint(max1800) {
                padding-bottom: 30px;               
            }
        }   
        .r-nav-link {
            &:hover,
            &.active {
                .dk-rnav-wrapper::before,
                .dk-nav-link {
                    color: $light-green-color;
                }
            }
        }         
    }   
}