@import "../../styles/mixins.scss";
@import '../../styles/variables';
.dk-custom-construct {   
    .cc-wrapper {
        max-width: 72%;
        position: relative;
        z-index: 4;
    }
    .cc-why-drees-desc {
        margin: 60px 0 50px 0;
        text-align: justify;
    }
}
.ccw-howwedoit-container {
    top: 105px !important;
    height: calc(100% - 105px);
    width: 100%;
    padding: 0 25px 0px 0px;
    .ccw-close-wrapper {
        height: 0px;
        button.btn-close {
            margin: 65px -10px 0 0;
            z-index: 9;
            border: solid 1px transparent;
        }
    }
    .ccw-accordion-header {
        position: relative;
        left: -23px;
        z-index: 2;
        min-width: 335px; 
    }
    .offcanvas-body {
        padding: 0;
    }
    .accordion-item {
        border: none;
        background-color: transparent;
    }
    .accordion-collapse {
        height: 100%;
        animation: fadeIn 1s;
    }
    .accordion-button::after {
        display: none;
    }
    .cch-steps {
        width: 88px;
        height: 88px;
        margin-right: 18px;
        border-radius: 50%;
    }  
    .icon-for-steps {
        width: 88px;
        height: 88px;
        border-radius: 50%;
    }
    .ccw-close-btn {
        top: 10px;
        right: 10px;
        border: none;
        box-shadow: none;
    }
    .ccw-close {
        width: 37px;
    }
    .ccw-accordion-title,
    .accordion-button:not(.collapsed) {
        width: auto;
        padding: 25px 0;
        font-size: 4rem;
        line-height: 4.8rem;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: #58595B;
    }
    .accordion-button:focus {
        box-shadow: none;
    }
 
    .ccs-bg-overlay {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;  
        right: 365px;   
        display: flex;
        align-items: flex-end;  
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    }
    .ccw-step-detail {
        width: 88%;
        padding: 25px;
        margin: 0 auto 25px auto;
        border-radius: 20px;
        background: rgba(37, 37, 37, 0.2);
        backdrop-filter: blur(5px);       
    }   
    .ccw-step-title {
        padding-bottom: 15px;
        font-size: 5rem;
        line-height: 6rem;
        letter-spacing: 0.1em;
        font-family: 'basic-sans', sans-serif;
    }
    .ccw-step-desc {
        font-size: 2rem;
        line-height: 2.7rem;
        letter-spacing: 0.1em;
    }
    @keyframes fadeIn {
        0% { opacity: 0.2; }
        50% { opacity: 0.5; }
        70% { opacity: 0.8; }
        100% { opacity: 1; }
    }
}
.slideArrows {
    display: inline-block;
    cursor: pointer;
}