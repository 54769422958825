@import url(../communityLanding/communityLanding.scss);
@import url(../favorite/favorite.scss);
@import "../../styles/mixins.scss";
@import '../../styles/variables.scss';

.pp-explore-wrapper,
.pp-view-wrapper {
    height: calc(100vh - 107px);
}
.pp-explore-wrapper {
    padding: 50px 240px 0px 50px;   
    overflow-y: auto;
    // z-index: 3;
    // position: relative;
    .pl-back-to-landing {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 500;
        font-size: 1.7rem;
        line-height: 4.4rem;
        letter-spacing: 0.03em;
        color: $white-color;
    }
    .pl-backarrow {
        width: 32px;
        margin-right: 8px;
    }
    .pl-heading { 
        margin-bottom: 20px;
        font-family: 'proxima-nova', sans-serif; 
    }
    .pl-heading,
    .pl-plan-cost { 
        font-size: 3rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
    }
    .pl-subheading{
        margin-bottom: 20px;
        font-family: 'basic-sans', sans-serif;
        font-size: 4rem;
        line-height: 4.8rem;
        letter-spacing: 0.1em;
    }
    .pl-plan-cost {
        font-family: 'basic-sans', sans-serif;
        letter-spacing: 0.06em;    
    }
    .pl-explore-btn {
        min-width: 275px;
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
    }
    .pv-collection-card {
        width: 436px;
        max-width: 436px;
        min-height: 535px;
        padding: 15px;
        border-radius: 11px;
        &.pqm-collection-card { padding: 0;}       
    }
    .cardCollection .pv-collection-card{
        padding: 0 !important;
    }
    .card-title img { 
        width: 32px; 
        &:hover { cursor: pointer; }
    }
    .pv-card1,
    .pv-card2 { z-index: 1;}
    .pv-card3,
    .pv-card4 { z-index: 0;}
    .mar-r50 {
        margin-right: 50px;
    }
    .pqm-card-location-info {
        .icon-location {
            height: 16px;
            margin-right: 5px;
        }
        .txt-address {
            color: $grey-color1;
        }
    }
    .card-text {
        .wasPrice {
            color: $grey-color1 !important;
        }
    }
}
.planCardCarousel {
    width: 100%;
    margin: 0 auto;
    height: 600px;
    @include breakpoint(max1920) {
        height: 570px;
    }
    .css-doq0dk {
        max-width: 1600px;
        margin: 0 auto;
        .css-1fzpoyk {
            opacity: 1 !important ;
            &:nth-child(1){
              left: 6% !important;
              transform: translateY(-50%) translateX(-33.3333%) scale(0.75) !important;
               .card {                 
                    &:after {                        
                        opacity: 0.7;
                    }                
                }
            }
            &:nth-child(2){
             left: 33% !important;
             transform: translateY(-50%) translateX(-66.6667%) scale(0.85) !important;
              .card {                 
                    &:after {                        
                        opacity: 0.3;
                    }                
                }
            }           
            &:nth-child(4){
                left: 67% !important;
               transform: translateY(-50%) translateX(-33.3333%) scale(0.88) !important;
                .card {                 
                    &:after {                        
                       opacity: 0.3;
                    }                
                }
            }
            &:nth-child(5){
               left: 93% !important;
               transform: translateY(-50%) translateX(-66.6667%) scale(0.75) !important;
                .card {                 
                    &:after {                        
                        opacity: 0.7;
                    }                
                }
            }
            &:nth-child(1),&:nth-child(2),&:nth-child(4),&:nth-child(5) {
                .card {
                    position: relative;                    
                    &:after {
                        content: '';
                        position: absolute; 
                        z-index: 1;
                        top: 0; 
                        left: 0; 
                        right: 0;
                        bottom: 0;
                        border-radius: 12px;
                        background: $white-color; 
                        margin: -20px;
                    }                
                }
            }
            .card {
                margin: 0;
                box-shadow: 11.1795px 11.1795px 33.5385px rgba(0, 0, 0, 0.1);
            }
        }
    }    
}

.OnlyFourPlanCardsWrapper {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    padding-right: 30px;
    padding-bottom: 30px;
    gap: 30px;
    max-width: 1950px;
    margin: 0 auto;
    .OnlyFourCards {        
        .card {        
            max-width: 385px;
            margin: 15px 0 -20px 0;
            @include breakpoint(max1921) {

            }
        }        
        .planCardImageWrapper {
            height: 230px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow: hidden;
            img.card-img-top {
                height: auto;
            }
        }
    }
}
.planCardImageWrapper {
    height: 260px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    img.card-img-top {
        height: auto;       
    }
}
.designChoiceIframe {
    padding: 0 !important;
    .video-tab-close button.btn-close {
        margin: 90px 35px 25px 0;
    }
    .video-tab-close {
        height: 0;
    }
    .view-elevation-wrapper {
        padding: 0;
        overflow: hidden;
    }
}