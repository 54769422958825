@import "../../styles/mixins.scss";
@import '../../styles/variables';

// #WhyDreesWaveAnimation {
//     position: fixed;
//     z-index: -100;
//     top: 50%;
//     left: 50%;
//     width: 100%;
//     height: auto;
//     z-index: -100;
//     transform: translateX(-50%) translateY(-50%);
// }

#whydreesWaveAnimationID {
    position: fixed;   
    width: 100%;
    height: auto;
    z-index: -100;
}
.dk-whydreesBg {
    position: absolute;
    right: 0;
    top: 50%;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    transform: translateX(-50%) translateY(-50%);
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }
}
.dk-why-drees-mainpanel {
    max-width: 1655px;
    margin: 0 auto;
}
.dk-why-drees-section {
    width: 100%;
    margin: 0 auto;
    padding: 75px 45px 75px 45px;
    background: rgba(0, 0, 0, 0);
    overflow-y: auto;
    h2 {
        margin: 0 0 35px 0;
        font-family: "basic-sans", sans-serif;
        font-weight: 400;
        font-size: 7.8rem;
        line-height: 8.7rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        color: $white-color;
    }
    h3 {
        max-width: 940px;
        font-family: "proxima-nova", sans-serif;
        font-weight: 400;
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 0.1rem;
        color: $white-color;
    }
}
.dk-wd-list-animation {
    margin: 100px 0 0 0;
    display: flex;
    justify-content: center;
}
.dk-why-drees-section {
    .animate-box {
        position: relative;
        width: 390px;
        height: 400px;
        margin-right: 25px;
        border-radius: 20px;
        box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        &:hover { cursor: pointer; }
    }
    .animate-box:last-child {
        margin-right: 0;
    }
    .animate-box img {
        position: absolute;
        width: auto;
        height: 105%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &:hover { cursor: pointer; }
    }
    .animate-box .box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "proxima-nova", sans-serif;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.1em;
        background: rgba(0, 0, 0, 1);
        color: $white-color;
        color: $light-green-color;
        cursor: pointer;        
    }
    .animate-box .box.box1 {
        animation-name: box1;
        animation-delay: 1s;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
    .animate-box .box.box2 {
        animation-name: box2;
        animation-delay: 1.5s;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
    .animate-box .box.box3 {
        animation-name: box3;
        animation-delay: 2s;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
    .animate-box .box.box4 {
        animation-name: box4;
        animation-delay: 2.5s;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }
    .w-75p {
        width: 75%;
    }
    @keyframes box1 {
        from {
            top: 0;
        }
        to {
            top: 75%;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    @keyframes box2 {
        from {
            top: 0;
        }
        to {
            top: 75%;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    @keyframes box3 {
        from {
            top: 0;
        }
        to {
            top: 75%;
            background: rgba(0, 0, 0, 0.5);
        }
    }
    @keyframes box4 {
        from {
            top: 0;
        }
        to {
            top: 75%;
            background: rgba(0, 0, 0, 0.5);
        }
    }

    @media only screen and (min-width: 1921px) {
        .dk-why-drees-section {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 75px 130px;
        }
        .animate-box img {
            width: 100%;
        }
    }
}
// #TransitionExpandCodepenid {
//     width: 100%;
//     height: 100%;
//     border: solid 2px red;
//     position: fixed;
//     top: 0px;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: 99;
//     //background: black;
// }
.curveanimation {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    animation-name: hideCurveAnimation;
    animation-delay: 2s;
    animation-duration: 0.75s;
}
@keyframes hideCurveAnimation {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 0.9;
    }
    20% {
        opacity: 0.8;
    }
    30% {
        opacity: 0.7;
    }
    40% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.4;
    }
    70% {
        opacity: 0.3;
    }
    80% {
        opacity: 0.2;
    }
    90% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}
.dk-lottie-title {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7.2rem;
    line-height: 8.8rem;
    letter-spacing: 0.37em;
    z-index: 9;
    
    &.dsgnInsTitle {
        @include breakpoint(max1920) {
            font-size: 5.9rem;
        }
    }
}
// .container {
//     pointer-events: none;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     overflow: hidden;
//     background: white;
// }
.App.WhyDreesLandingPage {
    .slide-enter,
    .slide-exit,
    .slide-exit-active {
        transform: none !important;
        transition: none !important;
    }
    .slide-exit {
        left: 0px;
    }
}
.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: initial;
}
.animationWhyDreesWrapper {
    &.slide-exit.slide-exit-active {
        left: 0px;
        .carousel .slider-wrapper {
            transition: none !important;
        }
        .carousel .slider.animated {
            transition: none !important;
        }
    }
}
