@import "../../styles/mixins.scss";
@import '../../styles/variables';

.dk-community-container {
    overflow-y: auto;
    .cc-wrapper { 
        max-width: 100%; 
        padding-top: 50px;
        position: relative;
        z-index: 4;
        @include breakpoint(max1400) {
            &.drees-testimonial-wrapper {
                padding: 40px 60px;
            }
            .cc-why-drees-title { padding-bottom: 20px; }
        }
    }   
    .community-slider {
        margin: 20px auto 0 auto;
    }
    
    .dk-community-heading {
        font-size: 6rem;
        line-height: 7.2rem;
    }
    .cc-button { 
        margin: 0 25px 25px 25px;
        min-width: 415px;
    }
    .carousel-inner { 
        overflow: visible; 
        display: flex;
        align-items: center;
        padding: 35px 0;
        height: auto;
    }
    .carousel { 
        padding: 20px 10px 20px 10px; 
        max-width: 950px;
        margin: 0 auto;
        height: auto !important;
        min-height: 520px;
        .carousel-control-prev-icon {
            background-image: url('../../assets/images/green-arrow-left.svg');
        }
        .carousel-control-next-icon {
            background-image: url('../../assets/images/right-arrow.svg');
        }
        .carousel-control-next, 
        .carousel-control-prev {
            width: 45px;
            height: 45px;
            top: calc(50% - 20px);
            &:hover { 
                width: auto;
                opacity: 1;
            }
        }
        @include breakpoint(min1920) {
            height: calc(100% - 320px);
            padding: 40px 10px 30px 10px;          
        }
        @include breakpoint(max1800) {
            min-height: 500px;
        }
    }
    @include breakpoint(max1400) {
        .cards { 
            width: 364px;
            max-width: 364px;
            height: 265px;
        }
    }
    @include breakpoint(max1800) {
        .dk-commmain-btn-wrapper {
            padding-bottom: 30px;
        }
    }
   .dk-commmain-btn-wrapper {
     max-width: 1200px;
     margin: 0 auto;
   }
} 
.our-team {
    padding: 18px 50px 30px 0;
}
.card-align {
    justify-content: center;
}
.ot-heading { 
    margin-bottom: 20px;
    font-family: 'proxima-nova', sans-serif; 
    font-size: 5rem;
    padding: 40px 0 20px 0;
}

.ot-back-to-community {
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 2.2rem;
    letter-spacing: 0.03em;
    color: $white-color;
    margin-bottom: 30px;
    .ot-backarrow {
        width: 32px;
        margin-right: 12px;
    }
}
.our-team-cards-wrapper {
    display: flex;
    overflow-y: auto;
    justify-content:center;
    flex-wrap: wrap;
    padding-bottom: 30px;
    gap: 30px;
    max-width: 1920px;
    max-height: calc(100vh - 497px);
    min-height: calc(100vh - 490px);
    margin: 0 auto;
    .our-team-cards {   
        .our-team {
            &:nth-child(4n) {
                padding-right: 0;
            }
        }     
        .card {        
            max-width: 350px !important;
            min-height: 500px !important;
            margin: 15px 0 -20px 0;
            @include breakpoint(max1921) {

            }
        }        
        .planCardImageWrapper {
            height: 368px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow: hidden;
            padding: 40px 0 0 0;
            img.card-img-top {
                height: fit-content;
            }
        }
    }
}

.card {
    padding: 15px;
    margin: 0 15px 15px 0;
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    .card-body {
        flex: 0 0 auto;
        padding: 0;
        p {
            padding-bottom: 15px;
            text-align: center;
         }
         .card-title {
            font-family: 'basic-sans', sans-serif;
            font-weight: 400;
            font-size: 2.5rem;
            line-height: 2.8rem;
            color: $dark-green-color;
            text-align: center;
        }
        .card-text {
            font-size: 2rem;
            line-height: 2.8rem;
            color: $light-green-color ;
        }
        .card-number{
            font-size: 2rem;
            line-height: 2.8rem;
            color: $dark-green-color ;
        }
        .card-location-info {
            p {
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-family: 'proxima-nova', sans-serif;
                letter-spacing: 0.14em;
                color: $grey-color1;
            }
            span { padding-left: 15px; }
            img {
            width: 15px;
            }
        }
        .dk-community-image { width: 240px; }
    }
}

.our-team-body-wrapper {
    padding: 0 0 25px 25px;
    height: calc(100% - 105px);
    top: 105px !important;
    right: 188px !important;
    &.our-team-Wrapper {
     background-image: url('../../assets/images/signup-bg.jpg');
     background-size: cover;
     background-position: center;
    .btn-close {
         margin: 20px 25px 0 0 !important
     }
 }
}

.ammenties-School-Wrapper {
    padding: 0;
    .offcanvas-header {
        height: 0;
        button.btn-close {
            position: absolute;
            margin: 0;
            z-index: 99;
            top: 15px;
            right: 15px;
            background-image: url('../../assets/images/close-white.svg');
        }
    }
    .offcanvas-body {
        padding: 0 !important;
    }
}
.ammenties-main-wrapper { 
    height: 100%; 
    .white-color {color: $white-color !important; }
    .ammenties-img-wrapper {
        flex: 0 0 75%;
    }
    .background-properties {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    h2 {
        font-size: 3rem;
        line-height: 3.4rem;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: $light-green-color;
    }
    p,ul li {
        font-size: 2.5rem;
        line-height: 3.2rem;
        font-family: 'proxima-nova', sans-serif;
        letter-spacing: 0.1em;
        color: $purplish-black-color ;
    }
    ul li {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        padding-bottom: 93px;
    }
    .dk-explore-right.dk-ammenties-right {
        padding: 150px 59px 40px;
        background-color: $purplish-black-color ;
    }
    .dk-explore-left.dk-ammenties-left {
        padding: 0;
        background-color: $grey-color;
    }   
    .ammenties-content-wrapper {
        flex: 0 0 25%;
        padding: 30px;
        overflow-y: auto;
        h2 { 
            padding: 20px 0 30px; 
            font-family: 'basic-sans', sans-serif;
            font-weight: 400; 
        }
    }
    .dk-ammenties-right {
        .scrollbar {
            width: 100%;
            max-height: 100%;
            overflow-y: auto;
        }        
        h2 { 
            padding-bottom: 82px;
            font-family: 'basic-sans', sans-serif;
            font-weight: 400; 
        }   
    }
    .ammenties-list img {
        width: 55px;
        margin-right: 15px;
    }
    @media only screen and (max-width: 1400px) {
        .dk-explore-right.dk-ammenties-right {
            padding: 40px 39px;
        }
        ul li {
            padding-bottom: 43px;
        }
        .ammenties-content-wrapper {
            padding: 20px;
        }
        .ammenties-content-wrapper h2 { padding: 20px 0; }
        .ammenties-list img {
            width: 45px;
        }
    }
}
.ourTeamWrapper {
    .card .card-body .card-title {
        font-size: 2.8rem;
        min-height: 50px;
        align-items: center;
    }
    .planCardImageWrapper {
        padding-top: 20px !important;
    }
    h3.subtitle{
        font-family: 'basic-sans', sans-serif;
        font-weight: 400;
        font-size: 2.8rem;
        line-height: 3rem;
        text-align: center;
        color: $dark-grey-color;
        padding-top: 18px;
    }
}