@import "../../styles/variables.scss";
.cc-main-container.testi-main-container {
    background-image: url('../../assets/images/testimonial-bg.png');
    background-size: cover;
    .cc-wrapper {
      max-width: 100%;
      position: relative;
      z-index: 4;      
    }
    .cc-slider-wrapper {
      bottom: 10px;
    }
}
.testimonial-slider {
  margin: 85px auto 100px auto;
  width: calc(100% - 320px);
  p.cc-why-drees-desc   {
    font-size: 3rem;
    line-height: 3.5rem;
    padding-bottom: 45px;
    max-width: 560px;
  }
}
.drees-testimonial-wrapper 
.cc-wrapper.drees-testimonial-wrapper {
   padding:75px 75px 5px 75px; 
}


/* .card1 {
    left:50%;
    margin-left:-13em;
    z-index:10;
    transform: rotateZ(-3deg);
    -moz-transform: rotateZ(-3deg);
    -webkit-transform: rotateZ(-3deg);
    transition: all 0.5s ease-out 0.3s;
    background-image: url('../images/testimonial-video-cover.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.card2 {
    top: -30px !important;
    margin-left: -7em;
    z-index:9;
    transition: all 0.5s ease-out 0.15s;
}
.card3 {
    left:50%;
    margin-left:-2em;
    top: -40px !important;
    z-index:8;
    transform: rotateZ(3deg);
    -webkit-transform: rotateZ(3deg); 
    -moz-transform: rotateZ(3deg); 
    transition: all 0.5s ease-out;
}

.start .card {
    top: 0;
    left: 0;
    /* transform: translate(-50%, -50%); */
    /* opacity:0; */
    /* transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg); 
} */
/* .card-container {
    position:relative;
    width: 100%;
    margin:4em auto;
}

.card {
    position:absolute;
    top:0;
    background-color: $white-color;
    box-shadow: 1px 1px 7px rgba(0,0,0,.65);
    border-radius:0em;
    padding:2em;
    width: 464px;
    max-width: 464px;
    height: 365px;
    -webkit-backface-visibility: hidden;
}

.card a {
    margin:0em -2em;
    display:block;
    width:120%;
    max-width:24em;
    position:relative;
    line-height:1em;
}

.card a:after {
    content: "";
    position:absolute;
    left:0;
    right:0;
    top:0px;
    bottom:1px;
    border-bottom:1px solid rgba(0,0,0,0.35);
    border-top:1px solid rgba(0,0,0,0.15);
}

.card h1 {
    margin-top:0;
}

.card p {
    line-height:1.75em;
    margin-bottom:0;
} */
/* 
.card-container:hover,
.card-container.hover {
    .card {
        opacity:0.9;
        transform: scale(0.95);
         -webkit-transform: scale(0.95);
        margin-left:-12em;
        &:hover,
        &.hover {
            opacity:1;   
            z-index:11;
                transform: scale(1);
         -webkit-transform: scale(1);
        }
    }
    .card1 {
        transform: rotateZ(0deg) scale(0.95);
        -webkit-transform: rotateZ(0deg) scale(0.95);
    }
    .card2 {
        left:0%;  
    }
    .card3 {
         left:100%;
    }
    
} */

// img { max-width:100% }

.card1 {
    animation-name: anim-one;
   
  }
  @keyframes anim-one {
    from { transform: translate(0) rotate(0deg);}
    50% {transform: translate(-30px, 5px) rotate(-45deg);}
    to { transform: translate(0) rotate(0deg);}
  }
  .card2 {
    animation-name: anim-two;
    
  }
  @keyframes anim-two {
    from { transform: translate(0) rotate(0deg);}
    50% {transform: translate(-15px) rotate(-25deg);}
    to { transform: translate(0) rotate(0deg);}
  }
  .three {
    //animation-name: anim-three;
    @keyframes anim-three {
      from { transform: translate(0) rotate(0deg);}
      50% {transform: rotate(-5deg);}
      to { transform: translate(0) rotate(0deg);}
    }
  }

  .cards { 
    position: relative; 
    width: 464px;
    max-width: 464px;
    height: 365px;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    margin-right: 50px;
  }
  .card { 
    position: absolute; 
    width: 100%; 
    height: 100%; 
    border: 1px solid black; 
    background-color: $white-color; 
    border-radius: 7px;
    padding-left: 5px;
    box-shadow: -2px 2px 3px rgba(0,0,0,0.3); 
    animation-iteration-count: initial; 
    animation-duration: .6s; 
    border: 20px solid $white-color;
    background-size: cover;
    background-position: center;
  }
   
.drees-testimonial-wrapper {
  .carousel-control-next,  
  .carousel-control-prev {
    width: 72px;
    opacity: 1;
  }
  .carousel-control-next img,
 .carousel-control-prev img { width: 45px;}
  .carousel {
    height: calc(100vh - 536px);
    .carousel-control-next, 
    .carousel-control-prev {
      opacity: 1 !important;
      max-width: 150px;
      min-width: 45px;
    }  
    .carousel-item {
      transition: all .35s ease-in-out;
    }     
  }
  .carousel-inner { 
    height: 100%; 
    overflow: visible;
  
  } 
  .carousel-inner {
 
    .carousel-item:nth-child(odd) {
      .one {
        transform: translate(110px, -35px) rotate(6deg);        
      }
      .two {
        transform: translate(49px, -28px) rotate(1deg);
      }
      .three {
        transform: rotate(-5deg);
      }
    }
    .carousel-item:nth-child(even) {
      .one {
        // transform: translate(110px, -35px) rotate(6deg);
       // background-image: url('../../assets/images/testimonial-image3.png');
        transform: translate(120px, -45px) rotate(9deg);
        animation-name: anim-three-center;
      }
      .two {
        // transform: translate(49px, -28px) rotate(1deg);
       // background-image: url('../../assets/images/testimonial-image2.png');
        transform: translate(90px, -30px) rotate(1deg);
        animation-name: anim-one-last;
      }
      .three {
        // transform: rotate(-5deg);
       // background-image: url('../../assets/images/testimonial-image.png');
        transform: translate(48px, -5px) rotate(-5deg);
        animation-name: anim-two-first;
      }
    }
  }
  .icon-player {
    // display: none;
    width: 55px;
    height: 55px;
    z-index: 99;
    position: absolute;
    left: 50%;
    top: 40%;
    cursor: pointer;
    svg {
      width: 55px;
      height: auto;
    }
  }
  .testi-single .icon-player  {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cc-button.dk-testi-button {
    margin-top: 40px;
    min-width: 769px;
  }
} 

  // .one {
  //   transform: translate(110px, -35px) rotate(6deg);
  //   background-image: url('../../assets/images/testimonial-image3.png');
  // }
  // .two {
  //   transform: translate(49px, -28px) rotate(1deg);
  //   background-image: url('../../assets/images/testimonial-image2.png');
  // }
  // .three {
  //   transform: rotate(-5deg);
  //   background-image: url('../../assets/images/testimonial-image.png');
  // }

    .one {
      animation-name: anim-one;
    }
    @keyframes anim-one {
      from { transform: translate(53px, -35px) rotate(0deg);}     
      to { transform: translate(110px, -35px) rotate(6deg);}
    }
    /* .two {
      animation-name: anim-two;
    }
    @keyframes anim-two {
      from { transform: translate(0, -28px) rotate(-5deg)}
      to { transform: translate(49px, -28px) rotate(1deg);}
  } */
  .three {
    animation-name: anim-three;
  }
  @keyframes anim-three {
    from { transform:translate(27px, -28px) rotate(1deg)}
    to { transform: rotate(-5deg)}
}

/* For next sliding */
.one-on-last {
  transform: translate(90px, -30px) rotate(1deg);
  animation-name: anim-one-last;
 }
 .two-on-first {
  transform: translate(48px, -5px) rotate(-5deg);
  animation-name: anim-two-first;
 }
 .three-on-center {
  transform: translate(120px, -45px) rotate(9deg);
  animation-name: anim-three-center;
 }

 @keyframes anim-one-last {
   from { transform: translate(53px, -35px) rotate(0deg);}
  
   to { transform: translate(90px, -30px) rotate(1deg)}
 }
 @keyframes anim-two-first {
   from { transform: translate(70px, -8px) rotate(-3deg)}
   to { transform: translate(48px, -5px) rotate(-5deg)}
}

@keyframes anim-three-center {
 from { transform:translate(27px, -28px) rotate(1deg)}
 to { transform: translate(120px, -45px) rotate(9deg)}
}  