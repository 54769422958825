.cc-main-container-ourstory {
    background: rgba(0,0,0,.4);
    background-size: cover;   
    .cc-wrapper {
        max-width: 67%; 
        position: relative;
        z-index: 4;      
    }
    .cc-why-drees-desc {
        margin: 60px 0 50px 0;
    }
    .cc-why-drees-title {
        max-width: 700px;
    }
    .btn-ourstory {
        min-width: 375px;
        max-width: 375px;
        padding: 0 35px;
        margin-bottom: 25px;
        font-size: 3rem;
        line-height: 3.4rem;
        height: 80px;
    }
    .btn-wrapper {
        max-width: 80%;
        padding-top:0;
        padding-bottom: 0;
    }
}
#DreesOurStoryAnimation {
    position: absolute;   
    z-index: -100;
    width: 100%;
    height: auto;
    margin-top: -55px;    
}
.ros-wherewebuild-title {
    position: absolute;
    bottom: 100px;
    left: 100px;
    font-size: 5rem;
    line-height: 6rem;
    letter-spacing: 0.1em;
    color: #000000;
}
.ros-text-wrapper {
    position: absolute;
    top: 250px;
    left: 100px;
    min-width: 554px;
    background-color: #2B2B2B;
    border-radius: 14px;
    animation: wwb-slideleft linear .6s;
}
.ros-wherewebuild-text {
    max-width: 550px;
    padding: 35px;
    font-size: 3.5rem;
    line-height: 5rem;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    text-align: left;
}
.ros-cities-count {
  font-size: 5.6rem;
  font-weight: 700;
  color: #A1B11A;
}
.ros-map-image {
    display: block;
    height: 100%;
    margin: 0 auto;
}

@keyframes wwb-slideleft {
    0% {
        left: -600px;
    }
    100% {
        left: 100px;
    }
}

// popover styles
.ccw-readourstory-wrappers {
    padding: 0;
    .dk-right-nav {
        top: 0;
    }
    .offcanvas-body {
        padding: 0;
    }
    .ccw-close-wrapper {
        height: 0px;
        button.btn-close {
            margin: 95px 210px 0 0;
            // z-index: 9;
            background-image: url('../../assets/images/close-white-rounded.svg');    
            border: solid 1px transparent;
        }
    }
    .ourstory-main-container {
        height: calc(100vh - 105px);
    }
    .ros-carousel {
        display: flex;
        align-items: center;
        height: calc(100% - 100px);     
        
    }
    .ros-carousel-inner {
        height: calc(100% - 121px);
        width: calc(100% - 140px);
        .prev,.next {
            display: none;
        }
    }
    .drees-ourstory-wrapper { padding: 40px; }
    
    .ros-card  {        
        min-height: 438px; 
        border-radius: 10px;
        &.first {           
            min-width: 580px;
        }
        &.second {
            min-width: 740px;
        }
    }
    .ros-text-card {
        padding: 55px 44px;
        background: rgba(51, 51, 51, 0.8);
        max-height: 600px;
        overflow-y: auto;
    }
    @keyframes osTextcard-right {
        0% {
            right: -700px;
        }
        100% {
            right: 0px;
        }
    }
    .ros-img-card {
        margin-right: 22px;
    }
    @keyframes osTextcard-right {
        0% {
            left: -200px;
        }
        100% {
            left: 40px;
        }
    }  
    .ros-ourstory-title {
        font-size: 10rem;
        line-height: 12.1rem;
        color: #2b2b2b;
        padding-left: 60px;
        position: relative; 
        animation: osTitle-top linear 1s;    
    }
    @keyframes osTitle-top {
        0% {
            top: -200px;
        }
        100% {
            top: 0px;
        }
    }
    @keyframes osTitle-two {
        0% {
            top: -200px;
        }
        100% {
            top: 0px;
        }
    }
    .text-green {
        color:#A1B11A;
    }
    .ccw-close-wrapper.ros-close-wrapper img {
     top: 30px;
     right: 30px;
    }
    .card-subtitle {
        margin-bottom: 4px;
        font-size: 3.5rem;
        line-height: 4.2rem;
        font-weight: 700;
    }
    .ros-ourstory-subtitle {
        margin-bottom: 20px;
        font-size: 2.95rem;
        line-height: 4.2rem;
    }
    .ros-ourstory-desc {
        font-size: 2.5rem;
    }
    .carousel .carousel-control-next,
    .carousel .carousel-control-prev {
        opacity: 1;
        width: 56px;
        height: 55px;
        top: calc(20vh - 20px);
       
        .carousel-control-next-icon {
            background-image: url('../../assets/images/next-arrow-white.svg');
        }
        .carousel-control-prev-icon {
           background-image: url('../../assets/images/prev-arrow-white.svg');
        }        
        &.white-color {
            img.black {
                display: none;
            }
        }
        &.black-color {
            img {
                width: 65px;
                height: 65px; 
            }
            img.white {
                display: none;
            }         
        }      
    }
    .carousel .carousel-control-next {
        right: 50px;
    }
    .carousel .carousel-control-prev {
        left: 50px;
    }
    .carousel-control-next-icon, 
    .carousel-control-prev-icon {
        width: 100%;
        height: 100%;
    }
    .btn-backourstory {
        display: block;
        margin: 15px auto 0 auto;
        min-width: 295px;
    }
    .prev, .next {
        &:hover { cursor: pointer; }
    }
    .prev{
        opacity: 1;
        width: 55px;
        height: 55px;
        left: 20px;
        top:240px;
        position:absolute;
        .prev-icon {
           background-image: url('../../assets/images/prev-arrow-white.svg');
        }
    }
    .next{
        opacity: 1;
        width: 55px;
        height: 55px;
        right:20px;
        top:240px;
        position:absolute;
        .next-icon {
            background-image: url('../../assets/images/next-arrow-white.svg');
        }
    }
    .btnColseReadStory {
        width: 40px;
        height: 40px;
        top: 25px;
        right: 210px;
        
        &.black-color {
            img.white { display: none;}
        }
        &.white-color {           
            img.green { display: none;}
        }
        &:hover { cursor: pointer; }
    }
}