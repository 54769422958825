.dk-comm-main-container { 
    width: 55%;
    height: 100vh; 
    .dk-community-heading {
        padding-bottom: 20px;
        font-size: 2rem;
        line-height: 2.2rem;
        letter-spacing: 0.14em;
        color: $black-color;
    }  
}
.communities-container {
    width: 95%;
    max-height: calc(100% - 80px);
    overflow-y: auto;
}
.card {
    padding: 15px;
    margin: 0 15px 15px 0;
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    .card-body {
        flex: 0 0 auto;
        padding: 0;
        p {
            padding-bottom: 15px;
            text-align: center;
         }
         .card-title {
            font-family: 'basic-sans', sans-serif;
            font-weight: 400;
            font-size: 2.5rem;
            line-height: 2.8rem;
            color: $dark-green-color;
            text-align: center;
        }
        .card-text {
            font-size: 2rem;
            line-height: 2.8rem;
            color: $light-green-color ;
        }
        .card-number{
            font-size: 2rem;
            line-height: 2.8rem;
            color: $dark-green-color ;
        }
        .card-location-info {
            p {
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-family: 'proxima-nova', sans-serif;
                letter-spacing: 0.14em;
                color: $grey-color1;
            }
            span { padding-left: 15px; }
            img {
            width: 15px;
            }
        }
        .dk-community-image { width: 240px; }
    }
}