@import "../../styles/variables.scss";
.cpd-plandetail-wrapper {
    height: calc(100vh - 105px);
    overflow-y: hidden;
    background: $dark-grey-color;    
    .cpd-header-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items:stretch;
        min-height: 272px;
        padding: 15px;
        background-size:cover;       
        background-position: center center;
    }
    .cpd-header-wrapper::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);
    }
    .cpd-row {
        position: relative;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        min-width: 33.33%;
        height: 100%;
        padding: 0;
        &:first-child {
            box-shadow:0px 2px 25px rgba(0, 0, 0, 0.3);
            z-index: 9;
        }
        .carousel-root {
            width: 100%;
        }
    }    
    .cpd-wraper {
        width:100%;       
    }
    .cpd-header-top,
    .cpd-header-bottom {
        position: relative;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .cpd-check-wrapper .cpl-check-input {
        width: 24px;
        height: 24px;
        min-height: 24px;
        padding: 0;
        margin: 0;
        border-radius: 0;
        border-color: $dark-green-color;
    }
    .cpd-check-wrapper .cpl-check-input:checked {
        background-image: url('../../assets/images/checkbox-check.svg');
        background-size: 16px;
        background-color: transparent;
    }    
    .cpd-label-compare {
        padding: 0 0 0 10px;
        font-size: 2rem;
        text-transform: capitalize;
        line-height: 2.3rem;
        color: $black-color;
    }
    .cpd-check-wrapper {
        padding: 8px;
        background-color: $white-color;
    }
    .cpd-form-check {
        flex: 0 0 50%;
        max-width: 50%;
        align-items: center;
    }
    .cpd-switch-label,
    .cpd-switch-input {
        padding: 2px 0;
        font-family: 'proxima-nova', sans-serif;
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.1em;
        color: $white-color;
        &:hover { cursor: pointer; }
    }
    .form-check-input.cpd-switch-input {
        min-height: 31px;
        width: 70px;
        margin: 0;
        margin: 0 15px;
        border-radius: 50px;
        background-image: url('../../assets/images/switch-round-green.svg') !important;
        background-size: 23px;
        background-position: 5px;
    }
    .form-check-input.cpd-switch-input:checked { 
        background-image: url('../../assets/images/switch-round-green.svg');
        background-color: $white-color;
        border-color: $white-color;
        background-position: 40px center;
    }
    .cpd-plan-sketch {
        min-height: calc(100% - 85px);
    }
    .cpd-radio-label {
        margin-right: 1px;
        color: $black-color;
    }
    .cpd-plan-heading {
        font-size: 3rem;
        line-height: 3.4rem;
        letter-spacing: 0.1em;
        font-family: 'proxima-nova', sans-serif;
    }
     .cpd-radio-wrapper {
        // margin-right: 15px;
        // padding: 16px 0px 16px 16px;
        background-color: $grey-color;
        margin-right: 15px;
        padding: 0;

        .vp-check { 
            margin: 0;
            padding: 0;
            width: 100%;
            position: relative;
            .form-check-label {
                min-height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                // font-size: 1.6rem;
                letter-spacing: -0.01px;
                font-size: 1.38rem;
                line-height: 14px;
            }
        }
    }
    .cpd-price-wrapper{
        padding: 17px;
        width: 100%;  
        background: $grey-color;     
    }
    .cpd-card-text {
        padding-right: 27px;
        font-family: 'proxima-nova', sans-serif;
        font-size: 2.5rem;
        line-height: 2.7rem;
        letter-spacing: 0.1em;
        color: $purplish-black-color;
    }
    .cpd-plan-desc-wrapper {
        min-height: calc(100vh - 460px); 
        padding: 10px 15px;
        background: $white-color;
    }
    .compareSlider {
        height: 100%;
        .carousel-item {
            height: 100%;
        }
        .carousel-indicators {
            margin: 0;
            padding: 0;
            height: 75px;
            display: flex;
            align-items: center;
            background: rgba(141,155,103,.9);
            button {
                width: 20px;
                height: 20px;
                margin-right: 15px;
                background: #C4C4C4;
                border-radius: 50%;
                opacity: 1;
                border: none;
                &.active {
                    background: $white-color;
                }
            }
        }
        .compareSliderImageWrapper {
            height: calc(100vh - 535px);
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: auto;
                transform: translate(-50%, -50%);
            }
        }
    }
    .cpd-plan-info {
        font-family: 'proxima-nova', sans-serif;
        font-size: 1.8rem;
        line-height: 2rem;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        color: $purplish-black-color;
    }
    .cpd-plan-info:nth-child(2) {
        color: $grey-color1
    }
    .cpd-info-wrapper {
        padding: 25px 0;
        text-align: center;
        border-bottom: 1.5px solid #858585;
    }
    .cpd-info-wrapper:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }
    .cpd-info-container {
        flex: 0 0 22%;
        max-width: 22%;       
    }
    .cpd-right-container { 
        flex: 0 0 75%;
        max-width: 75%;
        margin-left: 35px;
        .com-floorMap {
            height: calc(100vh - 540px);
            min-height: calc(100vh - 540px);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            .compare-floor-plan {
                width: 100%;   
                max-width: 100%;     
                height: auto;    
                max-height: 100%;   
            }
        }        
    }
    .cpd-detail-title {
        padding: 15px 0;
        font-family: 'proxima-nova', sans-serif;
        font-size: 2rem;
        line-height: 2.3rem;
        letter-spacing: 0.1em;
        color: $purplish-black-color;
    }
    .vp-check-input {
        width: 22px;
        height: 22px;
        margin: 0 10px;
        min-height: auto;
        padding: 0;
        border-color: #00462E;       
    }
    .vp-check-input:checked[type=radio] {
        background-image: url('../../assets/images/radio-btn.svg');
        background-color: $white-color;
        border-color: #00462E;
        background-size: 16px;         
    }   
    .cpd-plan-desc-wrapper {
        .vp-check {
            border-right: solid 1px #d9d9d9;
            &:last-child{
                border-right: none;
            }
        }
        .vp-check-input {            
            padding: 0;
            border: 0 !important;
            border-radius: 0 !important;
            margin: 0 !important;
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: none !important;
            background-color: transparent !important;
            background-size: 0;
            outline: none;
        }
    }
    .vp-check-input:checked[type=radio] + label.form-check-label {
        background: #cccccc;
    }
    .swipeToTopWrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background:rgba(34, 41, 47, 0.8);
        z-index: 99;   
        img {
            width: 90%;
        }    
        p.text {            
            color: $white-color;
            font-size: 30px;
        }
    }
    .compareAnotherPlan-Wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background:rgba(34, 41, 47, 0.8);
        z-index: 99;       
    }
    .bordered-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 430px;
        border-radius: 10px;
        border: 10px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);    
        transform: translate(-50%, -50%);    
        .vpl-plan-btn {
            background: #00462E;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-size: 43px;
                font-weight: 400;
                display: inline-block;
                margin-right: 15px;
                font-family: serif;
            }
        }  
    }
}
.anotherPlanCompare {
    width: calc(29% + 100px) !important;
}
.cpd-close-btn.btncompare {
    width: 40px;
    height: 40px;
    top: 15px;
    right: 20px;
    &:hover { cursor: pointer; }
}