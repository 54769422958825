@import "../../styles/mixins.scss";
@import '../../styles/variables.scss';
.w-50 {
    width: 50%;
}
.dk-submenu-section {
    width: 100%;
    padding:50px 55px 50px 65px;
    .title {
        margin: 0 0 30px 0;
        font-size: 2rem;
        font-family: 'basic-sans', sans-serif;
        font-weight: 500;
        color: $black-color;  
    }
    .dk-submenu-inrwrapper {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        max-height: calc(100vh - 210px);
    }
    .dk-submenu-inner-content {
        display: block;
        margin: 0 30px 30px 0;
        padding: 15px;
        width: 270px;
        height: auto;
        box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
        background: $white-color;
        border-radius: 10px;
        text-align: center;
        h3 {
            margin: 0 0 10px 0;
            font-size: 25px;
            line-height: 28px;
            color: $dark-green-color;
        }
        .nb-location-name {
            font-family: 'proxima-nova', sans-serif;
            font-size: 1.2rem;
            line-height: 1.4;
            color: $grey-color1;
            .pin-icon {
                width: 12px;
            }
            span { margin-left: 10px; }
        }    
        .nearbyimage {
            width: 100%;
            height: 130px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: auto;
                height: 130px;
            } 
        }
    }
}