//Color variables
$black-color            : #000000;
$black-color1           : #0F0F0F;
$white-color            : #ffffff;
$purplish-black-color   : #2B2B2B;
$grey-color             : #F2F2F2;
$grey-color1            : #7B7B7B;
$grey-color2            : #A6A6A6;
$dark-grey-color        : #58595B;
$light-green-color      : #A1B11A;
$dark-green-color       : #00462E;

// font-size variables
$font-size12            : 1.2rem;
$font-size20            : 2rem;
$font-size25            : 2.5rem;
$font-size30            : 3rem;
$font-size35            : 3.5rem;
$font-size40            : 4rem;
$font-size50            : 5rem;
$font-size60            : 6rem;


// font-family variables
$proxima-nova           : 'proxima-nova', sans-serif;
$basic-sans             : 'basic-sans', sans-serif;


/* Font family resource (from drees) */
@import url("https://use.typekit.net/qsl2ufj.css"); 

/*Details :- Proxima Nova:
font-family: 'proxima-nova', sans-serif;
font-weights:
    Thin:       100
    Light:      300
    Regular:    400  USED ONLY
    Medium:     500  USED ONLY
    SemiBold:   600
    Bold:       700
*/
/*Details :- Basic Sans:
font-family: 'basic-sans', sans-serif;
font-weights:
    Thin:       100
    ExtraLisgh: 200
    Light:      300
    Regular:    400  USED ONLY
    Medium:     500 
    SemiBold:   600
    Bold:       700
*/

// Cos adobe resource:
// @import url("https://use.typekit.net/nij0qlb.css"); 

//Icomoon font face rule
@font-face {
    font-family: 'icomoon';
    src:  url('../assets/fonts/icomoon.eot?bw6kva');
    src:  url('../assets/fonts/icomoon.eot?bw6kva#iefix') format('embedded-opentype'),
      url('../assets/fonts/icomoon.ttf?bw6kva') format('truetype'),
      url('../assets/fonts/icomoon.woff?bw6kva') format('woff'),
      url('../assets/fonts/icomoon.svg?bw6kva#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}