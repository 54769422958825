@import "../../styles/variables.scss";
@import "../../styles/mixins";

.dk-header {
    padding: 20px 25px;
    height: 107px; 
    background-color: $black-color1;
    opacity: 0.95;
    border: 1px solid $black-color1;
    backdrop-filter: blur(20px);
    margin-top: -107px;
    position: absolute;
    width: 100%;
    z-index: 999;
    .dk-logo { width: 116px; }
    a { 
        text-decoration: none;
        color: $white-color;
        &:hover,
        &:hover span:before { 
            color: $light-green-color;
        }
        &.nav-explore  .dk-header-link {
            font-size: 3rem;
        } 
    }
    .dk-header-link-wrapper {
        li {
            padding-right: 50px;
        }
        .dk-header-link {
            font-size: 2rem;
            color: $white-color;
        }
        a:hover,
        .dk-link-wrapper:hover,
        a.active,
        .dk-link-wrapper.active {
            cursor: pointer;
            span,
            .dk-icon-wrapper:before  { color: $light-green-color;}
        }
    }
    span {
        padding-right: 8px;
    }
    .dk-logo-wrapper {
        padding-right: 82px;
    }
    .dk-icon-wrapper { font-size: 3.2rem;}
    .hamburger-icon { font-size: 4.5rem; }
    .mar-r75 { margin-right: 55px;}

    @include breakpoint(max768) {
        .dk-header-link-wrapper,.nav-explore {
            display: none !important;
        }
    }
} 

.dk-promotions{
    padding-right: 179px !important;
}
.dk-signup-section {
    padding: 0;
    // background-image: url('../../assets/images/signup-bg.jpg');
    // background-size: cover;
    // background-position: center;
    .dk-close-wrapper {
        position: relative;
        right: 8px;
        border: none;
        background-color: transparent;        
        .dk-sign-close { width: 38px; }
    }
   
    .dk-signup-logo-wrapper { 
        padding: 20px 25px;
        height: 107px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .dk-logo-small { 
        min-width: 116px;
        width: 116px; 
        max-height: 116px;
        cursor: pointer;
    }
    .dk-form-container {
        padding: 0 40px;
        label {
            display: block;
            color: $white-color;
        }
       
        .dk-signup-heading {
            padding-bottom: 15px;
            font-family: 'basic-sans', sans-serif;
            font-weight: 400;
            font-size: 5rem;
            line-height: 6rem;
            letter-spacing: -0.02em;
            margin-top: -10px;
        }
        .dk-signup-desc {
            padding-bottom: 50px;
            font-family: 'proxima-nova';
            font-size: 3rem;
            line-height: 3.4rem;
           
        }
        .dk-field-wrapper { 
            flex: 0 0 20%; 
            padding: 0 15px;
        }
        .dk-lead-wrapper{
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 0 15px;
            .selected {
                font-size: 2.5rem;
                color: yellow;
            }
        }
        .dk-button-wrapper { padding-right: 12px; }
    }  

    .dk-signup-headingor{
        margin-top: -25px;
        padding: 0 0 10px 0;
        font-family: 'basic-sans', sans-serif;
        font-weight: 400;
        font-size: 3.5rem;
        line-height: 4rem;
        letter-spacing: -0.02em;
    }
    .dk-signup-desc-qr{
        padding-bottom: 20px;
        font-family: 'proxima-nova';
        font-size: 3rem;
        line-height: 3.4rem;
    } 
}


/* Explore menu styles */
.offcanvas { border: none; }
.dk-exploreMenu-wrapper {
    width: 100%;
}
.dk-explore-left {
    flex: 0 0 55%;
    max-width: 55%;
}
.dk-explore-right {
    flex: 0 0 45%;
    padding: 50px 50px 30px 100px;
    background-color: $grey-color;
    .reset-link-wrapper {
        .dk-header-link {
            font-size: 2rem;
           // color: #B11A23;
           color: $purplish-black-color;
           &:hover { color: $light-green-color; }
        }
        img {
            width: 32px;
            margin-right: 10px;
            margin-top: -4px;
        }
    }
}
.dk-menu-top-list {
    flex: 0 0 76%;
    max-height: 76%;
    overflow-y: auto;
    .wd-menu {
        padding-bottom: 30px;
        img.closeIcon {
            transform: rotate(45deg);
        }
        span.green{
            color: $light-green-color;
        }
    }
}
.dk-menu-top-list li {
    padding: 3px 3px 30px 3px;
}
.dk-menu-top-list li a img {
    width: 32px;
    padding: 0;
    margin-left: 20px;
}
.dk-menu-top-list li a {
    padding: 0;
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    font-size: 5rem;
    line-height: 5.5rem;
    color: $purplish-black-color;
}
.dk-submenu-wrapper { padding-left: 25px; }
.dk-submenu-wrapper li:last-child { padding-bottom: 15px; }
.dk-submenu-wrapper li a {
    font-size: 2.5rem;
    line-height: 2.8rem;
    color: $grey-color1;
}
.dk-exmenu-wrapper span,
.dk-exmenu-wrapper span::before {
    color: $purplish-black-color;
}
.dk-exmenu-wrapper span::before { font-size: 32px };
.dk-exmenu-wrapper span { font-size: 2rem;}
.dk-exmenu-wrapper .dk-icon-wrapper {
    padding-right: 10px;
}
.dk-exmenu-wrapper li {
     padding-right: 50px;
}
.dk-exmenu-wrapper a:hover span,
.dk-exmenu-wrapper a:hover span::before {
    color: $light-green-color;
} 
.dk-submenu-close {
    position: absolute;
    top: 20px;
    right: 50%;
    display: none;
    width: 25px;
}
.dk-menu-bottom-list { flex: 0 0 17%; }
.dk-menu-bottom-list .visit-comm-link {
    margin-bottom: 40px;
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    font-size: 3.5rem;
    line-height: 3.9rem;
    color: $grey-color1;
    padding-left: 0;
}
.dk-menu-bottom-list button:hover {color: $grey-color1;}
.visit-comm-arrow {
    width: 60px;
    padding-left: 25px; 
}
.dk-menu-top-list .card {
    padding: 20px 0 0 0;
    background-color: transparent;
    border: none;
}
.dk-explore-left {
    padding: 50px;
    background-color: $purplish-black-color;
}
.dk-explore-subleft {
    padding-right: 12px;
}
.dk-explore-subright {
    padding-left: 12px;
}
.dk-explore-left .tiles {
    margin-bottom: 25px;
    border-radius: 10px; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;   
    &:hover { cursor: pointer; }
}
.dk-explore-left .tiles:last-child {
    margin-bottom: 0;
}

.dk-exploreMenu-wrapper {
    .dk-explore-left a{
        margin-bottom: 25px;
        display: block;
        &:last-child {
            margin-bottom: 0;
        }
    }
   
}
.dk-explore-subleft,
.dk-explore-subright {
    flex: 0 0 50%;
}

.tile-one .dk-testi-btn {
    margin-top: 30px;
    padding: 10px;
    font-size: 2.5rem;
    line-height: 2.8rem;
    letter-spacing: 0.1em;
    font-family: 'proxima-nova', sans-serif;
    background-color: $white-color;
    border-radius: 5px;
    color: $purplish-black-color;
}
.tile-one p {
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-family: 'proxima-nova', sans-serif; 
    color: $white-color;
}
.tile-one {
    flex: 0 0 28%;
    padding: 30px;
    min-height: 281px;
    background-color: $light-green-color;
}
.tile-two {
    flex: 0 0 48%;
    min-height: 462px;
}
.tile-three {
    flex: 0 0 18%;
    min-height: 187px;
}
.tile-four {
    flex: 0 0 35%;
    min-height: 335px;
}
.tile-five {
    flex: 0 0 25%;
    //min-height: 207px;
    min-height: 240px;
}
.tile-six {
    flex: 0 0 34%;
    min-height: 355px;
}
.dk-btn-close {
    flex: 0 0 5%;
    padding-bottom: 35px;
    font-size: 3rem;
    line-height: 3.4rem;
    font-family: 'proxima-nova', sans-serif; 
    border: none;
    background: transparent;
    text-transform: uppercase;
    color: $light-green-color;
}
.dk-btn-close span {padding-right: 25px;}
.dk-btn-close img { width: 30px; } 
.ccw-close-wrapper.closebtn  button{
    margin: 15px 15px 0 0 !important;
}

@media only screen and (max-width: 1400px) {
    .dk-menu-top-list {
        flex: 0 0 65%;
        max-height: 65%;
    }
    .tile-one .dk-testi-btn { margin-top: 15px; font-size: 1.8rem; }
    .tile-one { max-height: 190px; padding: 18px;  }
    p.pb-4 { padding-bottom: 1rem !important;}
    .dk-menu-bottom-list .visit-comm-link { font-size: 3rem;}
}
.ccw-watch-video-wrapper.signupWrapper {
   // background: black !important;
    background-image: url('../../assets/images/signup-bg.jpg');
    background-size: cover;
    background-position: center;
    top: 0px !important; 
    .offcanvas-body {
        padding: 0 !important;
    }
   .btn-close {
        margin: 20px 25px 0 0 !important
    }
    .ccw-content-wrapper {
        padding: 0;      
    }   
}
.near-by-communities {
    width: calc(55% + 1px) !important;
    .offcanvas-body {
        padding: 0;
        overflow: hidden;
        .cpl-wrapper {
            overflow-y: auto;
        }
    }   
}
.modal-Signup {
    .modal-body {
        h2,p {
            font-family: 'basic-sans';
            font-size: 4rem;
            font-weight: 400;
            letter-spacing: 3px;
            line-height: 50px;
        }
        p {
            margin-top: 50px;  
            letter-spacing: 1px;       
        }
    }
}
.OffcanvasExploreMenu {
    transition: transform .5s ease-in-out !important;
}