@import url('./planCollections.scss');
@import url('./planView.scss');
.pqm-main-container {
    max-width: 1700px;
    margin: 0 auto;
}
.pv-collection-card.pqm-collection-card {
    position: relative;
   
    .qmoveImageWrapper {
        min-height: 225px;
        max-height: 225px;
        overflow: hidden;
    }
    
}