@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.fav-top-wrapper {
    padding: 55px 50px;
    .fav-title {
        padding-bottom: 20px;
        font-family: 'basic-sans', sans-serif;
        font-weight: 400;
        font-size: 6rem;
        line-height: 7.2rem;
        letter-spacing: 0.1em;
        color: $black-color1;
    }
    .fav-desc {
        font-size: 3rem;
        line-height: 3.7rem;
        letter-spacing: 0.1em;
        color: $dark-grey-color;
    }
}
.favorites-wrapper {
    height: calc(100% - 190px);
    .card-body-top-wrapper,
    .card-body-mid-wrapper {
        flex: 1 1 auto;
    }
    @include breakpoint(max1400) {
        height: calc(100% - 281px);
    }
    .nav-tabs {
        padding-left: 50px;
    }
    .tab-content {
        height: calc(100% - 35px);
        padding: 25px 50px 0 50px;
        background: $purplish-black-color ; 
        .tab-pane { height: 100%; }
        .fav-button {
            min-width: 380px;
        }
        .fav-container {
            width: 100%;
            justify-content: center;
            align-items: center;
            overflow-y: auto;
            gap: 0 20px;
            .card {
                position: relative;
                flex: 0 0 385px;
                max-width: 385px;
                border-radius: 10px;
                height: auto;
                align-self: stretch;             
                margin: 0 0 20px 0;
                .text-start:empty {
                    display: none;
                }
                .favoriteCardPrice {
                    font-size: 20px;
                    text-align: left;
                    color: $light-green-color  ;
                }
                .sprfavlast {
                    margin-top: 5px;
                }
                .planCardImageWrapper{
                    height: 230px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;   
                    .card-img-top{
                        height: 110%;
                        width: auto;                       
                    }          
                }
            }
            .card-title {
                .favorited-title {
                    padding-right: 10px;
                    font-size: 2.5rem;
                    line-height: 3rem;
                    color: $dark-green-color ;
                }
                img { width: 32px; }
            }
            .dk-community-image { 
                width: 100%;
                padding-bottom: 20px; 
            }           
        } 
    }
    .nav-tabs {
        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 49px;
            padding: 0;
            font-size: 2.5rem;
            line-height: 2.9rem;
            font-family: 'proxima-nova', sans-serif;;
            letter-spacing: 0.1em;  
            background: $grey-color2 ;
            border: 1px solid $grey-color2 ;
            border-radius: 10px 10px 0px 0px; 
            color: $purplish-black-color ;
            &.active {
                background: $purplish-black-color ;
                border: 1px solid $purplish-black-color ;
                color: $white-color;
            }
        }
        .nav-item {
            margin-right: 15px;
            text-align: center;
            button {
                min-width: 250px;
                margin: 0;
                padding: 0 15px;
            }
        }
    }
}
.fav-desc-text {
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: $grey-color1 ;
    span { padding: 0 10px; }
    &:last-of-type { padding-bottom: 5px; }
}
.fav-separator {
    width: 100%;
    margin: 15px 0 20px;
    opacity: 0.5;
    background-color: $grey-color1 ; 
}
.fav-bottom-wrapper {
    .fav-vp-btn {
        min-height: 43px;
        font-size: 2rem;
        line-height: 2.3rem;
        font-weight: normal;
    }
}
.dk-MainContentWrapper {
    .fav-bottom-container { 
        height: 100%; overflow: hidden; 
        &.RightMenu { padding-right: 187px; }
    }
}
@media only screen and (max-width: 1400px) { 
    .communities-container.fav-container .card {
        flex: 0 0 30%;
    }
}