@import "../../styles/mixins.scss";
@import '../../styles/variables';

// #DreesKioskHomeAnimation {
//     position: fixed;    
//     top: 50%;
//     left: 50%;
//     width: 100%;
//     height: auto;
//     z-index: -100;
//     transform: translateX(-50%) translateY(-50%);     
// }

#HomeWaveAnimationID {
    position: fixed;    
    width: 100%;
    height: auto;
    z-index: -100;     
}
.dk-homeBg {
    position: absolute;    
    right: 0;
    top: 50%;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    transform: translateX(-50%) translateY(-50%);   
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }
}

.dk-home-section {
    position: fixed;
    top: 105px;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    padding:50px 50px 170px 50px;
    overflow-y: auto;
    .dk-home-text-panel {
        width: 600px;
        margin: 0 50px 0 0;
        .welcome-text,
        .sub-heading {
            letter-spacing: 0.1em; 
            text-transform: uppercase;
        }
        .welcome-text {
            font-size: 3rem;
            line-height: 3.6rem; 
            margin-bottom: 15px;
            color: $light-green-color; 
        }
        .sub-heading {  
            font-size: 5rem;
            line-height: 6rem;
            color: $white-color;
            max-width: 600px;
        }
    }
}

.dk-home-animation-panel {
     width: 1150px; 

     .animate-box {    
        border-radius: 20px;
        box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.3);
        overflow: hidden;   
        .boxbgImage {
            position: absolute;
        }    
        .box { 
            position: absolute;
            top:0;
            bottom:0;
            left: 0;      
            right: 0;
            font-family: $proxima-nova;
            font-weight: 400;
            font-size: 2.5rem;
            line-height: 2.5rem;
            letter-spacing: 0.1em;
            background: rgba(0,0,0,1);
            color: #A1B11A;    
            &.box1 {
                animation-name: Homebox1;
                animation-delay: 1s;
                animation-duration: .5s;
                animation-fill-mode: forwards;
            }
            &.box2 {
                animation-name: Homebox2;
                animation-delay: 1.5s;
                animation-duration: .5s;
                animation-fill-mode: forwards;
            }
            &.box3 {
                animation-name: Homebox3;
                animation-delay: 2s;
                animation-duration: .5s;
                animation-fill-mode: forwards;
            }
            &.box4 {
                animation-name: Homebox4;
                animation-delay: 2.5s;
                animation-duration: .5s;
                animation-fill-mode: forwards;
            }
            &.box5 {
                animation-name: Homebox5;
                animation-delay: 3s;
                animation-duration: .5s;
                animation-fill-mode: forwards;
            } 
        }        
    }
}
.dk-hometestimonial-wrapper {
    position: absolute;
    bottom: 0;
    height: 122px; 
    background-color: $purplish-black-color;
    .drs-htsbox {
        min-height: 118px;
    }
    img.icon-chat {
        width: 40px !important;
    }
    .dk-testimonial {
        padding: 0 0 0 20px;
        font-size: 2.5rem;
        letter-spacing: 0.1em;
        color: $white-color; 
        font-family: $proxima-nova;
        font-weight: 400;
        &.line{
            padding-top: 6px;
            font-size: 4rem;
            font-family: 'basic-sans', sans-serif;
            font-weight: 400; 
        }
    }
    .txt-testimonial {
        max-width: 75%;
        text-align: left;
        line-height: 3.4rem;
    }
    .carousel .slider-wrapper.axis-horizontal .slider .slide {
        flex-direction: row !important;
        flex-flow: row !important;
    }
}

@keyframes Homebox1 {  
    from {top: 0; }
    to{top: 90%;  background: rgba(0,0,0,0.5); }
}

@keyframes Homebox2 {
    from {top: 0; }
    to {top: 80%;  background: rgba(0,0,0,0.5);}
}
@keyframes Homebox3 {
    from {top: 0; }
    to {top: 80%; background: rgba(0,0,0,0.5); }
}
@keyframes Homebox4 {
    from {top: 0;}
    to {top: 80%;  background: rgba(0,0,0,0.5);}
}
@keyframes Homebox5 {
    from {top: 0;}
    to {top: 80%;  background: rgba(0,0,0,0.5);}
}

@media only screen and (min-width: 1921px) {
    .dk-home-section {
        align-items: center;
    }
    .dk-home-animation-panel {
        align-content: center;
    }
    .dk-home-text-panel {
        width: auto;
        max-height: 80%;
    }
    .dk-home-animation-panel {
        width: 1420px;
        height: 80%;
    }
}

// transition style started

// .drsTransFavorite {
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     right:0; 
   
//     z-index: 99;
//     color: #000;
//     box-sizing: border-box;
//     background-color: yellow;    
//     width: 100%;
//     &#slidemeFavorite {
//         transition: all 800ms cubic-bezier(0.19, 1, 0.56, 1);  
//         transform: translateX(100%);   
//         &:target {   
//             transform: translateX(0); 
//         }
//     }    
// }

// Signup transition
.drsTranSignup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    z-index: 99;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 45px 25px;
    &#SlideSignup {
        transition: all 600ms cubic-bezier(0.19, 1, 0.56, 1);  
        transform: translateY(-100%);
        &:target{
            transform: translateY(0);
        }
    }
}

// .alert-enter {
//     opacity: 0;
//     transform: scale(0.9);
//   }
//   .alert-enter-active {
//     opacity: 1;
//     transform: translateX(0);
//     transition: opacity 300ms, transform 300ms;
//   }
//   .alert-exit {
//     opacity: 1;
//   }
//   .alert-exit-active {
//     opacity: 0;
//     transform: scale(0.9);
//     transition: opacity 300ms, transform 300ms;
//   }

//   .slide-enter {
//     opacity: 0;
//     transform: translateX(-500px) scale(0.6);
//   }
//   .slide-enter-active {
//     opacity: 1;
//     transition: all 400ms ease-in;
//     transform: translateX(0) scale(1);
//   }
//   .slide-exit {
//     opacity: 1;
//   }
//   .slide-exit-active {
//     opacity: 0;
//     transition: all 400ms ease-in;
//     transform: translateX(500px) scale(0.6);
//   }

.slide-enter{
  transition: transform 980ms ease-in-out;
}
.slide-exit {
  transition: transform 1000ms ease-in-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 30px;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
   transform: translateX(-100%);
}

// .slide-enter,.slide-exit,.slide-exit,.slide-exit-active{ 
//     transition: none;
// }