* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App, #root { height: 100%; }
html {
    font-size: 62.5%;
    height: 100%;
}
body {
    height: 100%;
    position: relative;
    margin:0;
    padding:0;
    font-size: 1.6rem;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 400;
    padding-top: 107px;
}
h1,h2,h3,h4,h5,h6,p {
    margin: 0;
}
a {
    text-decoration: none;
    &:hover {
        color: #fff;
        cursor: pointer;
    }
}

ul {
    list-style-type: none;
}

img { height: auto; }

//Color classes
.white-color { color: $white-color !important; }
.text-green { color: $light-green-color; } 
//Font size classes
.font-size-12 { font-size: pxToRem(12px); }
.font-size-20 { font-size: pxToRem(20px); }
.font-size-25 { font-size: pxToRem(25px); }
.font-size-30 { font-size: pxToRem(30px); }
.font-size-35 { font-size: pxToRem(35px); }
.font-size-40 { font-size: 4rem}
.font-size-50 { font-size: pxToRem(50px); }
.font-size-60 { font-size: pxToRem(60px); }

//font family classes
.basicSans-regular {font-family: 'basic-sans', sans-serif; font-weight: 400; }
.proximaNova-regular{font-family: 'proxima-nova', sans-serif !important; font-weight: 400 !important;}

/* spacing classes */
.mb-50 { margin-bottom: 50px; }
.mr-25 { margin-right: 25px; }
.mr-t15 {margin-top: 15px !important;}
.mb-25 { margin-bottom: 25px; }
.pr-25 { padding-right: 25px; }
.pb-15 { padding-bottom: 15px; }
.pb-25 { padding-bottom: 25px; }
.pb-35 { padding-bottom: 35px; }
.mt-10 { margin-top: 10px; }
.mb-10 { margin-bottom: 10px; }
.pb-35 { padding-bottom: 35px; }
