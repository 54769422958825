@import "../../styles/variables.scss";
.drs-contem-sliderwrapper {
    .carousel-item {
        height: calc(100vh - 105px);
    }
}
.fs-contem-background:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.fs-bg-black .fs-contem-background::before {
    background-color: rgba(0,0,0,.8);
 }
 .fs-bg-white .fs-contem-background::before {
    background-color: rgba(255,255,255,.8);
 }
 .fs-bg-black .ccw-close-wrapper button.btn-close {
    background-image: url('../../assets/images/close-white-rounded.svg');
 }
.fs-contem-wrapper {
    height: calc(100% - 105px);
    padding: 0;
    .fs-gallery-wrapper:hover { cursor: pointer; }
    .offcanvas-body {
        padding: 0;
    }
    .ccw-close-wrapper {      
        height: 0;
        button.btn-close {
            margin: 70px 25px 0 0 !important;
            z-index: 99;
        }
    }     
    .fs-close-wrapper img {
        width: 45px;
    }
    .fs-contem-image-container {
        position: relative;
        width: 899px;
        height: 718px;
        margin: 0 auto;
    }
    .fs-contem-style-wrapper {
        position: absolute;
        border-radius: 10px;
    }
    .fs-contem-image1 {
        left: -270px;
        bottom: 102px;
        z-index: 3;
        min-width: 345px;
        min-height: 345px;
    }
    .fs-contem-image2 {
        right: 70px;
        top: 40px;
        z-index: 2;
        width: 899px;
        height: 718px;
    }
    .fs-contem-image3 {
        right: -340px;
        top: -48px;
        width: 462px;
        height: 523px;
        z-index: 3;
    }
    .fs-contem-text {
        right: -370px;
        bottom: 50px;
        width: 570px;
        height: 413px;
        z-index: 1;
    }
    .fs-desc-text {
        max-width: 82%;
        flex: 0 0 82%;
        font-size: 2.3rem;
        line-height: 3rem;
        letter-spacing: 0.1em;
        font-family: 'proxima-nova', sans-serif;
        color: $white-color ;
    }
    .fs-gallery-icon {
        width: 45px;
        margin-right: 15px;
    }
    .fs-gallery-text {
        font-size: 2.5rem;
        line-height: 2.8rem;
        letter-spacing: 0.1em;
        font-family: 'proxima-nova', sans-serif;
        color: $white-color ;
    }
    /* Styles for bohemain */
    .fs-bohemain-image1 {
        left: -190px;
        bottom: 65px;
        z-index: 3;
        min-width: 316px;
        min-height: 298px;
    }
    .fs-bohemain-image2 {
        right: 170px;
        top: 70px;
        z-index: 2;
        width: 1004px;
        height: 669px;
    }
    .fs-bohemain-image3 {
        left: 700px;
        top: 130px;
        width: 657px;
        height: 442px;
        z-index: 3;
    }
    .fs-bohemain-text {
        left: 755px;
        top: -50px;
        width: 677px;
        height: 413px;
        z-index: 2;
    }
    .fs-boh-desc-text,
    .fs-boh-gallery-text {
        color: $purplish-black-color !important;
    }
    .fs-desc-bot-wrapper {
        z-index: 3;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 40px 40px 40px;
    }
    .ccw-close-wrapper.fs-close-wrapper img {
        right: -5px;
        top: -5px;
    }
    .carousel a.carousel-control-next,
    .carousel a.carousel-control-prev {
        opacity: 1;
        width: 55px;
        height: 55px;
        top: calc(50vh - 125px);       
    }
    .carousel a.carousel-control-next {
        right: 40px;
    }
    .carousel a.carousel-control-prev {
        left: 40px;
    }
    button.carousel-control-next, 
    button.carousel-control-prev {
        opacity: 1;
        width: 100%;
        height: 100%;
    }
}
// view galleary css
.drs-cviewgalleryWrapper {
    .vg-photo-wrapper {
        flex: 0 0 45%;
        max-width: 45%;
    }
    .vg-right-wrapper {
        flex: 0 0 55%;
        max-width: 55%;
        padding-top: 50px;
        background-color: $purplish-black-color;
    }
    .ccw-watch-video-wrapper {
        padding: 0;
    }
    .vg-images-wrapper {
        width: 257px;
        height: 315px;
    }
    .vg-arrow-up {
        width: 45px;
        transform: rotate(-90deg);
        &:hover { cursor: pointer; }
    }
    .vg-close-wrapper img {
        right: 15px;
        top: 15px;
    }
    .vg-style-heading {
        text-align: center;
        img {
            width: 261px;
            padding: 45px 0;
        }    
        .title {
            display: inline-block;
            margin: 45px 0;
            font-size: 5.3rem;
            font-weight: 300;
            text-transform: uppercase;
            color: $grey-color1  ;
            border-top: solid 2px $grey-color1  ;
        }
    }
    .vg-images-list-wrapper {
        margin: 0 50px 30px;
        padding: 0;
        height: calc(100% - 236px);
        overflow-y: auto;
    }
    .vg-images-wrapper {
        border-radius: 10px;
    }
    .vg-image-list {
        margin-right: 50px;
        margin-bottom: 50px;
        div.vg-images-wrapper {
            opacity: .4;
            &.active {
                opacity: 1;
            }
            &:hover { cursor: pointer; }
        }
    }
}
.fs-bg-white .vg-right-wrapper {
    border: solid 1px transparent;
}