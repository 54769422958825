.loader-wrapper {   
    .loader-image-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.7);
        z-index: 9999;
    }
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
           -moz-transform: translate(-50%,-50%);
           -ms-transform: translate(-50%,-50%);
           -o-transform: translate(-50%,-50%);
    }    
}