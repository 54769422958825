@import "../../styles/variables.scss";
.view-body-offcanvas {
    padding: 0;
    .ccw-close-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        .btn-close {
            margin: 0;
            z-index: -1;
        }
    }
}
.view-ele-offcanvas {
    padding: 0;
    .ccw-close-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        .btn-close {
            margin: 0;
            background-image: url('../../assets/images/close-white-rounded.svg');
        }
    }
    .view-elevation-wrapper {
        padding: 0;
        .ve-left-wrapper {
            position: relative;
            flex: 0 0 67%;
            max-width: 67%;
        }
        .tag-wrapper {
            display: inline-block;
            padding: 10px 42px;
            margin: 8px;
            background: rgba(1, 1, 1, 0.7);
            border-radius: 10px;
            font-family: 'proxima-nova', sans-serif;
            font-size: 3rem;
            line-height: 3.4rem;
            letter-spacing: 0.1em;
            color: $white-color;
        }
        .ve-images-wrapper {
            min-width: 253px;
            min-height: 200px;
            border-radius: 10px;
            overflow: hidden;
            &:hover { cursor: pointer; }
            .td-testimonial-vc {
                width: auto;
                position: absolute;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.4;
                &.active {
                    opacity: 1;
                }
            }
        }
        .ve-image-list {
            margin-right: 25px;
            margin-bottom: 25px;
            font-size: 2rem;
            line-height: 2.3rem;
            letter-spacing: 0.1em;
            color: $white-color;
        }
        .ve-image-list span {
            display: inline-block;
            margin-bottom: 5px;
        }
        .vg-style-heading {
            margin: 60px 0 50px;
            font-size: 3rem;
            line-height: 3.6rem;
            letter-spacing: 0.1em;
            color: $white-color;
        }
        .vg-right-wrapper {
            width: 100%;
            background-color: $purplish-black-color;
        }      
        .vg-images-list-wrapper {
            margin: 0 25px 30px;
            padding: 0;
            max-height: calc(100% - 210px);
            overflow-y: auto;
        }
    }
    &.designChoiceIframePlanview {
        padding: 0 !important;
        button.btn-close {
           background-image: url('../../assets/images/close-green-rounded.svg');
           margin-right: 5px;
        }
        .video-tab-close {
            height: 0;
        }
        .view-elevation-wrapper {
            padding: 0;
            overflow: hidden;
        }
        
    }
}
